// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Typography, Stepper, Step, StepButton } from '@mui/material';
import moment from 'moment';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { isEmpty, omit, get, isNil } from 'lodash';
import PropTypes from 'prop-types';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import EventsStep1 from './createSteps/EventsStep1';
import EventsStep2 from './createSteps/EventsStep2';
// import EventsStep3 from './createSteps/EventsStep3';
import EventsStep4 from './createSteps/EventsStep4';

const EventsCreate = props => {
  const { updateMode } = props;
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(updateMode);
  const [stepLoading, setStepLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [cache, setCache] = useState({});
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  // console.log(userNGOSelected, id);
  const EventsForm = useForm({
    defaultValues: {
      // step 1
      name: '',
      message: '',
      // step 2
      formatted_address: '',
      country: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip: '',
      date_from: '',
      date_to: '',
      // step 3
      media_id: '',
      banner_id: ''
    }
  });

  const steps = [
    {
      label: 'Event Details',
      content: <EventsStep1 updateNgo={userNGOSelected.virtual_artist_id} updateMode={updateMode}/>
    },
    {
      label: 'Location',
      content: <EventsStep2 updateMode={updateMode}/>
    },
    {
      label: 'Profile and Banner',
      content: <EventsStep4 setStepLoading={setStepLoading} cache={cache}
       setCache={setCache} setUploadLoading={setUploadLoading} />
    }
  ];

  const handleStep = step => async () => {
    if (await EventsForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async () => {
    if (await EventsForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = data => {
    if (activeStep !== steps.length - 1) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    /* if (!updateMode) {
      const { ngoId } = formData;

      const NGOResponse = await http(apiHost, token.accessToken, token.tokenType).get(
        `/api/web/v2/non-profit-ngos/${ngoId}`
      );

      const { success, output } = get(NGOResponse, 'data', {});
      if (!success) {
        return;
      }

      // console.log(output, 'data output');

      const { country, street_address: streetAddress, street_address_2: streetAddress2, city, state, zip } = output;
      formData = {
        ...formData,
        country,
        street_address: streetAddress,
        street_address_2: streetAddress2,
        city,
        state,
        zip
      };
    } */

    if(formData.address_manual_mode === true){
      // pre-data for formated address
    const address1 = !isNil(formData.street_address) && !isEmpty(formData.street_address) ? `${formData.street_address}, ` : '';
    const address2 = !isNil(formData.street_address_2) && !isEmpty(formData.street_address_2) ? `${formData.street_address_2}, ` : '';
    // new formated address
    const formattedAddress =  `${address1}${address2}${formData.city}, ${formData.state}, ${formData.zip}, ${formData.country}`;
    formData = {
      ...formData,
      venue_id: userNGOSelected.id,
      formatted_address: formattedAddress,
      skip_address_lookup: 1
    }
    }else{
      formData = {
        ...formData,
        venue_id: userNGOSelected.id
      }
    }

    setLoading(true);
    const { date_from: dateFrom, date_to: dateTo } = formData;
    const timeStart = moment(dateFrom).utc().valueOf() / 1000;
    const timeEnd = moment(dateTo).utc().valueOf() / 1000;
    let response;
    if (!updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/new-event', {
          ...omit(formData, ['date_from', 'date_to']),
          datesStartEnd: [`${timeStart}, ${timeEnd}`],
          // artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'event',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else if (updateMode && id) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/update-event', {
          ...omit(formData, ['date_from', 'date_to', 'full_address']),
          id,
          hour_date_start: timeStart,
          hour_date_end: timeEnd,
          // artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'event',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else {
      console.log('Something is wrong');
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success: success2, error } = get(response, 'data', {});
    if (!success2 && error) {
      console.log(error);
      console.log(error.fieldErrors);
      error.fieldErrors.forEach(({ field, message }) =>
        EventsForm.setError(field, { type: 'required', message }, { shouldFocus: true })
      );

      return;
    }

    navigate('/events');
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    // console.log(output, 'events');
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    const {
      name,
      message,
      country,
      city,
      state,
      zip,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      formatted_address: fullAddress,
      media_id: avatarId,
      banner_id: bannerId,
      _related
    } = output;

    const fromDate = get(output, '_related.event_dates.0.start_date');
    const toDate = get(output, '_related.event_dates.0.end_date');
    const imageOriginal = get(output, '_misc.avatar.original_image');
    const bannerOriginal = get(output, '_misc.banner.original_image');

    EventsForm.reset({
      // step 1
      name,
      message,
      association: (_related.beneficiary_non_profit_ngos.length > 0 ? _related.beneficiary_non_profit_ngos[0].name : ''),
      // step 2
      formatted_address: fullAddress,
      country,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      city,
      state,
      zip,
      date_from: fromDate ? new Date(moment(fromDate * 1000)) : '',
      date_to: toDate ? new Date(moment(toDate * 1000)) : '',
      // step 3
      media_id: avatarId,
      banner_id: bannerId
    });

    setCache({ media_id: imageOriginal, banner_id: bannerOriginal });
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/Events');
  };

  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(uploadLoading){
      setLoading(true);
    }

    if(!uploadLoading){
      setLoading(false);
    }

}, [uploadLoading]);

  return (
    <Layout title="Create Events" breadcrumb={['Home', <Link to="/Events">Events</Link>, 'Create Events']}>
      <div className="Events-create">
        <div className="d-flex flex-column col-sm-8 align-items-center justify-content-center mx-auto">
          {activeStep > 0 && (
            <Typography color="primary" variant="h5" className="Events-title-name text-right mx-auto">
              {`Events: ${EventsForm.getValues('name')}`}
            </Typography>
          )}
          <Stepper nonLinear activeStep={activeStep} className={`py-4 align-self-stretch ${uploadLoading ? 'v-hidden' : 'show'}`}>
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <FormProvider {...EventsForm}>
            <Box
              component="form"
              onSubmit={EventsForm.handleSubmit(stepperSubmit)}
              className="align-self-stretch"
              autoComplete="off"
            >
              <div className="d-flex flex-column align-items-stretch">
                <Typography color="primary" variant="h1" className="text-center mx-auto">
                  {steps[activeStep].label}
                </Typography>
                {steps[activeStep].content}
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="btn-forms"
                  disabled={activeStep === 0 || uploadLoading}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep + 1 === steps.length ? (
                  <Button type="submit" variant="outlined" className="btn-forms" disabled={loading || stepLoading}>
                    {updateMode ? 'Update and Publish' : 'Save and Publish'}
                  </Button>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    {/* <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleDraft}
                    >
                      Save as Draft
                    </Button> */}
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </FormProvider>
        </div>
      </div>
    </Layout>
  );
};

EventsCreate.defaultProps = {
  updateMode: false
};

EventsCreate.propTypes = {
  updateMode: PropTypes.bool
};

export default EventsCreate;
