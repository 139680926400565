// Packages
import React from 'react';
import PropTypes from 'prop-types';

const Footer = () => {
  return <footer />;
};

Footer.defaultProps = {
  path: null
};

Footer.propTypes = {
  path: PropTypes.string
};

export default Footer;
