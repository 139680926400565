// Packages
import React from 'react';
// import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
// import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Relatives
import BasicPopover from '../../../components/BasicPopover';
// import UserContext from '../../../contexts/UserContext';

const EventsStep1 = () => {

  const { control } = useFormContext();
  // const { userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/fundraisers/create');
  };


  return (
    <>
      <Typography className="inline-block text-muted">
        <span className="mb-4">
          If you want to create a single day event, please{' '}
          <a className="text-primary pointer" onClick={handleClick}>
            click here
          </a>{' '}
          to create a Fundraiser.
        </span>
      </Typography>
      <Grid className='mt-2' item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Event name
                </label>
                <BasicPopover message="This is what the public will see." />
              </div>
              <TextField
                {...field}
                fullWidth
                // label="Events Name"
                variant="standard"
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Tell us about this event
                </label>
                <BasicPopover message="Tell people about your event." />
              </div>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

/* EventsStep1.defaultProps = {
  updateMode: false
};

EventsStep1.propTypes = {
  updateMode: PropTypes.bool
}; */

export default EventsStep1;
