// Packages
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import { Payment } from '@picklejarapp/stripe-payments';
import UserContext from '../../../contexts/UserContext/UserContext';
import AppContext from '../../../contexts/AppContext/AppContext';

const ArtistPayment = props => {
  const { selectedItem, onChangeArtist } = props;
  const { id, token, getUser } = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const [state, setState] = useState({
    amount: '',
    memo: '',
    isValidAmount: false,
    isValidMemo: false
  });

  const [showPayment, setShowPayment] = useState(false);

  const user = getUser();
  let ownerType = 'artist';
  let ownerId;
  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType,
    id
  };

  if (selectedItem.artist) {
    ownerType = 'artist';
    ownerId = selectedItem.artist.id;
  }

  if (selectedItem.band) {
    ownerType = 'band';
    ownerId = selectedItem.band.id;
  }

  const paymentDone = () => {
    setState({
      amount: '',
      memo: '',
      isValidAmount: false,
      isValidMemo: false
    });
    setShowPayment(false);
  };

  useEffect(() => {
    const isValidAmount = state.amount === '' || /^[0-9]+(\.[0-9]{1,2})?$/.test(state.amount);
    setState(prevState => ({ ...prevState, isValidAmount }));
  }, [state.amount]);

  useEffect(() => {
    const isValidMemo = state.memo.trim().length > 0;
    setState(prevState => ({ ...prevState, isValidMemo }));
  }, [state.memo]);

  const handleAmountChange = e => {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, amount: value }));
    const isValidAmount = value === '' || /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
    setState(prevState => ({ ...prevState, isValidAmount }));
  };

  const handleMemoChange = e => {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, memo: value }));
    const isValidMemo = value.trim().length > 0;
    setState(prevState => ({ ...prevState, isValidMemo }));
  };

  const handleMakePayment = () => {
    setShowPayment(true);
  };

  return (
    <>
      {selectedItem.handle ? (
        <div className="payment">
          <div className="row">
            <div className="col-md-3">
              <div className="artist-detail">
                <h5 className="mt-0">Selected Artist</h5>
                <div className="artist-detail__profile">
                  <img src={selectedItem.avatar.originalImage} alt="profile" />
                  <h5 className="mb-1 mt-3">{selectedItem.handle}</h5>
                  <Link className="small" onClick={onChangeArtist}>
                    Change Artist
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="enter-amount">
                <img src="/images/money_ico.svg" alt="money-icon" />
                <p>How much do you wanna pay</p>
                <input
                  type="text"
                  placeholder="$0"
                  value={state.amount}
                  onChange={handleAmountChange}
                  className="price-input"
                />
                {!state.isValidAmount && state.amount !== '' && (
                  <span style={{ color: 'red' }}>Please enter a valid amount</span>
                )}
                <textarea
                  type="text"
                  rows={5}
                  placeholder="Enter Memo"
                  value={state.memo}
                  onChange={handleMemoChange}
                />
                {!state.isValidMemo && <span style={{ color: 'red' }}>Please enter a memo</span>}
                <button
                  type="button"
                  disabled={!state.isValidAmount || !state.isValidMemo}
                  onClick={handleMakePayment}
                  className="border-0 btn btn-primary mt-3 rounded-0 d-block mx-auto"
                >
                  Make Payment
                </button>
              </div>
            </div>
            <div className="col-md-4">
              {showPayment && (
                <Payment
                  apiHost={apiHost}
                  userDetails={userDetails}
                  ownerId={ownerId}
                  amount={state.amount}
                  message={state.memo}
                  ownerType={ownerType}
                  onSuccessPayment={paymentDone}
                  stripeCustomerId={user.params.profile.customerStripeId}
                  stripePkKey={process.env.REACT_APP_STRIPE_PK_KEY}
                  stripeSkKey={process.env.REACT_APP_STRIPE_SK_KEY}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ArtistPayment.defaultProps = {
  selectedItem: { handle: null },
  onChangeArtist: noop
};

ArtistPayment.propTypes = {
  selectedItem: PropTypes.object,
  onChangeArtist: PropTypes.func
};
export default ArtistPayment;
