import React, { forwardRef, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import sum from 'lodash/sum';
import isEqual from 'lodash/isEqual';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Alert } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

LinearScale.scaleFontColor = '#fff';

const VeneusProfileChart = forwardRef((props, ref) => {
  const isMobile = useMediaQuery('(min-width: 767px)');
  const { dataYearly, labelsYearly, dataMonths, labelsMonths, dataWeekly, labelsWeekly } = props;
  const [tips, setTips] = React.useState();
  const [currentLabels, setCurrentlabels] = useState(labelsMonths);
  const [currentData, setCurrentdata] = useState(dataMonths);
  const [axisChart, setAxischart] = useState('');

  const labels = currentLabels;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: axisChart,
    plugins: {
      legend: {
        display: false,
        labels: {
          fontColor: 'white'
        }
      },
      title: {
        fontColor: 'white'
      },
      labels: {
        render: 'labels',
        fontColor: [
          'rgb(255,255,255)',
          'rgb(255,255,255)',
          'rgb(255,255,255)',
          'rgb(255,255,255)',
          'rgb(255,255,255)',
          'rgb(255,255,255)',
          'rgb(255,255,255)'
        ]
      }
    }
  };

  const data = {
    labels,
    scaleFontColor: 'white',
    datasets: [
      {
        axis: axisChart,
        label: '',
        data: currentData,
        backgroundColor: 'rgb(253 126 20)',
        borderRadius: '25px',
        color: '#ffffff'
      }
    ]
  };

  const handleChange = event => {
    const { value } = event.target;

    if (value === 'yearly') {
      setCurrentdata(dataYearly);
      setCurrentlabels(labelsYearly);
    }

    if (value === 'weekly') {
      setCurrentdata(dataWeekly);
      setCurrentlabels(labelsWeekly);
    }

    if (value === 'monthly') {
      setCurrentdata(dataMonths);
      setCurrentlabels(labelsMonths);
    }

    setTips(value);
  };

  useEffect(() => {
    if (isMobile) {
      setAxischart('x');
    } else {
      setAxischart('y');
    }
  });

  return (
    <div ref={ref} className="DashboardChart DashboardChart__leftside">
      <div className="DashboardChart__actions">
        {!isEqual(sum(dataMonths), 0) && !isEqual(sum(dataWeekly), 0) && !isEqual(sum(dataYearly), 0) && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Filter</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={tips}
              label="Tips"
              onChange={handleChange}
            >
              {!isEqual(sum(dataYearly), 0) && <MenuItem value="yearly">Yearly Tips</MenuItem>}
              {!isEqual(sum(dataWeekly), 0) && <MenuItem value="weekly">Weekly Tips</MenuItem>}
              {!isEqual(sum(dataMonths), 0) && <MenuItem value="monthly">Monthly Tips</MenuItem>}
            </Select>
          </FormControl>
        )}
        {isEqual(sum(dataMonths), 0) && isEqual(sum(dataWeekly), 0) && isEqual(sum(dataYearly), 0) && (
          <>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </>
        )}
      </div>
      <div className="canvas_container">
        {isEqual(sum(currentData), 0) && (
          <Alert className="bg-transparent border-0" color="light">
            <h3 className="m-0 py-5 text-center">Coming Soon</h3>
          </Alert>
        )}
        {!isEqual(sum(currentData), 0) && <Bar width="50%" height="auto" options={options} data={data} />}
      </div>
    </div>
  );
});

VeneusProfileChart.defaultProps = {
  dataMonths: [0, 0, 0, 0, 0],
  dataWeekly: [0, 0, 0, 0, 0, 0, 0],
  dataYearly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  labelsMonths: ['Week #1', 'Week #2', 'Week #3', 'Week #4', 'Week #5'],
  labelsWeekly: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  labelsYearly: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

VeneusProfileChart.propTypes = {
  dataMonths: PropTypes.array,
  dataWeekly: PropTypes.array,
  dataYearly: PropTypes.array,
  labelsMonths: PropTypes.array,
  labelsWeekly: PropTypes.array,
  labelsYearly: PropTypes.array
};

export default VeneusProfileChart;
