/* eslint-disable react/no-danger */
// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Container, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';

// Relatives
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';

const ModalTerms = props => {
  const { openModal, onClose } = props;
  const { apiHost } = useContext(AppContext);
  const [terms, setTerms] = useState('');
  const [loading, setLoading] = useState(false);

  const init = async () => {
    const response = await http(apiHost)
      .get('/api/web/settings/default/get-value?group=ngos_legal&key=terms_and_conditions&language=en-US')
      .catch(() => {});

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success) {
      return;
    }

    setTerms(output);
  };

  useEffect(() => {
    setLoading(true);
    init();
  }, []);

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="sm">
        <div className="modal-header">
          <Typography id="modal-modal-title" component="h6" className="title">
            Terms and conditions
          </Typography>
          <CloseIcon className="close" onClick={handleClose} />
        </div>
        <div className="modal-body">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {loading ? 'Loading...' : <div dangerouslySetInnerHTML={{ __html: terms }} />}
          </Typography>
        </div>
      </Container>
    </Modal>
  );
};

ModalTerms.defaultProps = {
  openModal: false,
  onClose: noop
};

ModalTerms.propTypes = {
  openModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default ModalTerms;
