// Packages
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

// Relatives
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';

// Relatives
import Fundraiser from './Fundraiser';

const FundraiserList = props => {
  const { items, className, containerNode, onCallback, canAutoScroll } = props;
  const [observedNodeRef, setObservedNodeRef] = useState(null);
  const containerRef = useRef(null);

  const onRefSet = useCallback(ref => setObservedNodeRef(ref));

  return (
    <div ref={containerRef} className={classNames('fundraiser-list', { [className]: className })}>
      <InfiniteScroll
        containerNode={containerNode || containerRef?.current}
        observedNode={observedNodeRef}
        threshold={0.1}
        onCallback={onCallback}
        disabled={items.length === 0 || !canAutoScroll}
      >
        {items.length > 0 && items.map((item, i) => (
          <Link key={i} to={`/fundraisers/${item.id}`}>
            <Fundraiser ref={i === items.length - 1 ? onRefSet : null} {...item} />
          </Link>
        ))}
        {items.length === 0 && (
          <div className="list-empty">
            <div className="list-empty__icon" />
            <div className="list-empty__label">No results found</div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

FundraiserList.defaultProps = {
  className: '',
  containerNode: null,
  canAutoScroll: true,
  onCallback: noop,
  items: []
};

FundraiserList.propTypes = {
  className: PropTypes.string,
  containerNode: PropTypes.object,
  canAutoScroll: PropTypes.bool,
  onCallback: PropTypes.func,
  items: PropTypes.array
};

export default FundraiserList;
