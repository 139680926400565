/* eslint-disable no-unneeded-ternary */
// Packages
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Skeleton, useMediaQuery } from '@mui/material';

const VenuesFilter2 = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(isMobile ? false : true);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className="left_bar-container">
      {loading ? (
        <Accordion
          expanded={expanded}
          onChange={() => {
            handleExpanded();
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>MENU</Typography>
          </AccordionSummary>
          <AccordionDetails className="notMargins">
            <div className="MenuOfLinks">
              <NavLink
                to={{ pathname: '/register/venues/create' }}
                className={({ isActive }) => `menuLink font-tablet ${isActive ? 'active' : 'normal-link'}`}
              >
                <AddIcon /> Add Location{' '}
              </NavLink>
              <NavLink
                to={{ pathname: '/venues/profile' }}
                className={({ isActive }) => `menuLink font-tablet ${isActive ? 'active' : 'normal-link'}`}
              >
                Venue Profile <ArrowForwardIosIcon />
              </NavLink>
              <NavLink
                to={{ pathname: '/venues/highlights' }}
                className={({ isActive }) => `menuLink font-tablet ${isActive ? 'active' : 'normal-link'}`}
              >
                Venue Highlights <ArrowForwardIosIcon />
              </NavLink>

              <NavLink
                to={{ pathname: '' }}
                className={({ isActive }) => `menuLink font-tablet disabled ${isActive ? 'active' : 'normal-link'}`}
              >
                Gallery of Pictures <ArrowForwardIosIcon />
              </NavLink>
              <NavLink
                to={{ pathname: '' }}
                className={({ isActive }) => `menuLink font-tablet disabled ${isActive ? 'active' : 'normal-link'}`}
              >
                Featured Artists <ArrowForwardIosIcon />
              </NavLink>
              <Link to={{ pathname: '' }} className="menuLink font-tablet disabled">
                Artist Connect <ArrowForwardIosIcon />
              </Link>
              <Link to={{ pathname: '' }} className="menuLink font-tablet disabled">
                Business Information <ArrowForwardIosIcon />
              </Link>
              <Link to={{ pathname: '' }} className="menuLink font-tablet disabled">
                PickCoin Balance <ArrowForwardIosIcon />
              </Link>
              {/* <Link to={{ pathname: '/' }} className="menuLink font-tablet menuLink--black-rounded">
                Account Upgrade
              </Link> */}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={400} />
      )}
    </div>
  );
};

export default VenuesFilter2;
