import { createTheme } from '@mui/material';
import { BREAKPOINTS } from './Breakpoints';

export const primaryColor = '#ff8830';
export const secondaryColor = '#00ff00';
export const theme = createTheme({
  breakpoints: BREAKPOINTS,
  typography: {
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 'clamp(32px, 10vw, 3em)',
      marginTop: '0.67em',
      marginBottom: '0.67em',
      letterSpacing: '0.025em',
      fontWeight: 'normal'
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '2.8em',
      marginTop: '0.50em',
      marginBottom: '0.50em',
      letterSpacing: '0.025em'
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '2.2em',
      marginTop: '0.40em',
      marginBottom: '0.40em',
      letterSpacing: '0.025em'
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.5rem',
      marginTop: '0.30em',
      marginBottom: '0.30em',
      letterSpacing: '0.025em'
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.25rem',
      marginTop: '0.20em',
      marginBottom: '0.20em',
      letterSpacing: '0.025em'
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.125rem',
      marginTop: '0.10em',
      marginBottom: '0.10em'
    },
    body1: {
      fontFamily: 'Montserrat, sans-serif',
      marginTop: '0.67em',
      marginBottom: '0.67em'
    },
    body2: {
      fontFamily: 'Montserrat, sans-serif',
      marginTop: '0.67em',
      marginBottom: '0.67em'
    }
  },
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    }
  },
  props: {
    MuiTypography: {}
  }
});
