// Packages
import React, { useState, useEffect } from 'react';
// Relatives
import Layout from '../../layouts/Layout';
import ArtistPayment from './components/ArtistPayment';
import ArtistSearch from './components/ArtistSearch';

const ArtistPayNow = () => {
  const [selectedItem, setSelectItem] = useState({ handle: null });

  useEffect(() => {}, []);
  const handleItemSelection = item => {
    console.log('selection', item);
    setSelectItem(item);
  };
  const handleChangeArtist = () => {
    setSelectItem({ handle: null });
  };
  return (
    <Layout title="ArtistPayNow" breadcrumb={['Home', 'Artist Pay Now']}>
      <div className="artist-pay-now">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {selectedItem.handle === null ? <ArtistSearch onSelection={handleItemSelection} /> : null}
            </div>
            <div className="col-12">
              {selectedItem.handle ? (
                <ArtistPayment selectedItem={selectedItem} onChangeArtist={handleChangeArtist} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArtistPayNow;
