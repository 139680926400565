const getItemName = sessionKey => {
  return `web-${sessionKey}-state`;
};

export const loadState = sessionKey => {
  try {
    const serializedState = localStorage.getItem(getItemName(sessionKey));
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = (sessionKey, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(getItemName(sessionKey), serializedState);
  } catch {
    // ignore write errors
  }
};

export const clearState = sessionKey => {
  try {
    localStorage.removeItem(getItemName(sessionKey));
  } catch {
    // ignore write errors
  }
};
