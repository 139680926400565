/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
// Packages
import React, { /* useEffect, */ useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';

// Relatives
// import MaskedInput from '../../../components/MaskedInput';
import AddressLookup from '../../../components/AddressLookup';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';

const VenuesStep1 = props => {
  const { cache, editor } = props;
  const { control, setValue, register } = useFormContext();
  const [emailValid, setEmailValid] = useState();
  const [newEditor, setNewEditor] = useState(editor);

  const validationEmail = email => {
    if (email !== '') {
      // eslint-disable-next-line no-useless-escape
      const emailRules =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(email.value, emailRules.test(email));
      if (emailRules.test(email)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
        setValue('email', email);
      }
    }

    return;
  };

  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');

      return;
    }

    // eslint-disable-next-line camelcase
    const {
      formatted_address,
      streetNumber,
      streetName,
      postalCode,
      stateName,
      locality,
      occupancyIdentifier,
      countryCode,
      coordinates
    } = address;

    setValue('country', countryCode);
    setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    setValue('street_address_2', occupancyIdentifier);
    setValue('city', locality);
    setValue('state', stateName);
    setValue('zip', postalCode);
    setValue('lat', coordinates.latitude || null);
    setValue('lon', coordinates.longitude || null);

    fieldOnChange(formatted_address);
  };

  const handleIsManualMode = isManual => {
    if (isManual === true) {
      setValue('address_manual_mode', true);
    }

    if (!isManual) {
      setValue('address_manual_mode', false);
    }
  };

  return (
    <>
      <input type="hidden" {...register('address_manual_mode')} />
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Venue Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Venue Name"
              variant="standard"
              inputProps={{
                maxLength: 255
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid className={newEditor ? 'd-flex' : 'visually-hidden'} item xs={12}>
        <Grid
          onClick={() => {
            setNewEditor(false);
          }}
          item
          xs={12}
        >
          <Controller
            name={newEditor ? 'formatted_address' : ''}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                label="Address"
                variant="standard"
                style={{ color: '#fff' }}
                disabled
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid className={newEditor ? 'visually-hidden' : 'show'} item xs={12}>
        <Controller
          name={newEditor ? '' : 'formatted_address'}
          control={control}
          render={({ field, fieldState }) => {
            const { value, onChange } = field;

            return (
              <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Address
                </label>
                <AddressLookup
                  onChangeMode={handleIsManualMode}
                  onChange={handleChange(onChange)}
                  value={value || cache.full_address}
                  placeholder="Write your address..."
                >
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'street_address'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Address is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address"
                          variant="standard"
                          placeholder="Street Address or P.O. Box"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'street_address_2'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address 2"
                          variant="standard"
                          placeholder="Apt, Suite, Unit Building (optional)"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex">
                    <Controller
                      name={newEditor ? '' : 'city'}
                      control={control}
                      rules={{ required: newEditor ? false : 'City is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="City"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={newEditor ? '' : 'state'}
                      control={control}
                      rules={{ required: newEditor ? false : 'State is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="State"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={newEditor ? '' : 'zip'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Zip is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Zip"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'country'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Country is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Country"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </AddressLookup>
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            );
          }}
        />
      </Grid>
      <Controller
        name="phone_number"
        control={control}
        render={({ fieldState, field }) => (
          <TextField
            {...field}
            fullWidth
            label="Phone Number"
            variant="standard"
            placeholder="XXXXXXXXX"
            defaultValue="+1"
            sx={{ maxWidth: '400px' }}
            InputProps={{
              inputComponent: PhoneInput,
              maxLength: 40
            }}
            helperText={
              fieldState.error && (
                <Typography variant="p" color="error">
                  {fieldState.error.message}
                </Typography>
              )
            }
          />
        )}
      />
      <Grid item xs={12}>
        <label className="email-input">
          Email
          <input
            className="MuiInput-input"
            {...register('email')}
            onChange={event => {
              validationEmail(event.target.value);
            }}
            type="email"
            required={emailValid}
          />
          <span>{emailValid ? 'Email is Invalid' : ''}</span>
        </label>
      </Grid>
    </>
  );
};

VenuesStep1.defaultProps = {
  editor: false,
  cache: null
};

VenuesStep1.propTypes = {
  editor: PropTypes.bool,
  cache: PropTypes.object
};

export default VenuesStep1;
