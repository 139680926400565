import React from 'react';
import { Link } from 'react-router-dom';

// Relatives
import Layout from '../../layouts/Layout';
import VenuesFilter2 from './components/VenuesFilter2';

const VenueHighlights = () => {
  return (
    <Layout key="venuesProfile" title="Venues Profile" breadcrumb={[<Link to="/">Home</Link>, 'Highlights']}>
      <div className="dashboard-index d-flex align-items-start">
        <div className="col-12 col-md-3">
          <VenuesFilter2 />
        </div>
        <div className="col-12 col-md-9 px-5 mt-5 mt-md-0">
          <div className="row">
            <div className="col-md-3">
              <Link to={{ pathname: '/venues/highlights/menu' }}>
                <img src="../images/icons/menu.svg" alt="Menu" className="img-fluid" />
              </Link>
            </div>
            <div className="col-md-3">
              <Link to={{ pathname: '/venues/highlights/drinks' }}>
                <img src="../images/icons/drinks.svg" alt="Drinks" className="img-fluid" />
              </Link>
            </div>
            <div className="col-md-3">
              <Link to={{ pathname: '/venues/highlights/calendar' }}>
                <img src="../images/icons/calendar.svg" alt="Calendar" className="img-fluid" />
              </Link>
            </div>
            <div className="col-md-3">
              <Link to={{ pathname: '/venues/highlights/specials' }}>
                <img src="../images/icons/specials.svg" alt="Specials" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VenueHighlights;
