import { CircularProgress } from '@mui/material';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Progress } from 'reactstrap';
import { multiply, round, divide, isEqual, get, isNil } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';

import Layout from '../../../../layouts/Layout';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext';
import http from '../../../../services/api/http';

const MenuForm = () => {
  const { id } = useParams();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate } }));
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState('');
  const [menuDetails, setMenuDetails] = useState(false);
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const [mssgUploadProgressBanner, setMssgUploadProgressBanner] = useState({ progress: null, color: 'dark' });

  const uploadProgressBanner = data => {
    const uploadPercentProgress = round(multiply(divide(data.loaded, data.total), 100));
    const messageUploadOnProgress = uploadPercentProgress;
    const messageUploadEnd = 100;

    if (isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadEnd, color: 'success' });

      setTimeout(() => {
        setMssgUploadProgressBanner({ progress: null, color: 'dark' });
      }, 3000);

      return messageUploadEnd;
    }

    if (!isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadOnProgress, color: 'warning' });
      return messageUploadOnProgress;
    }

    return false;
  };

  const handleFileSubmit = async e => {
    e.preventDefault();
    const formValid = validator.current.allValid();

    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(true);

      return;
    }

    if (!token) {
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('file', file.target.files[0]);
      setFileLoading(true);

      const response = await http(
        apiHost,
        token.accessToken,
        token.tokenType,
        { 'Content-Type': 'multipart/form-data' },
        uploadProgressBanner
      )
        .post('/api/web/media/manage/upload', formData)
        .catch(() => {});

      if (!response || !response.data) {
        setFileLoading(false);

        return;
      }

      const { success, output } = get(response, 'data', {});
      if (!success && output) {
        Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
        setFileLoading(false);

        return;
      }
      const mediaId = get(response, 'data.data.0.id', '');

      if (id) {
        updateMenu(mediaId);
      } else {
        createMenu(mediaId);
      }
    }
  };

  const createMenu = async mediaId => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/venue-has-menu', {
        venue_id: userNGOSelected.id,
        media_id: mediaId,
        menu_type: 1
      })
      .catch(() => {});

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
      setFileLoading(false);

      return;
    }

    setFileLoading(false);
    navigate('/venues/highlights/menu');
  };

  const updateMenu = async mediaId => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .patch(`/api/web/v2/venue-has-menu/${id}`, {
        venue_id: userNGOSelected.id,
        media_id: mediaId,
        menu_type: 1
      })
      .catch(() => {});

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
      setFileLoading(false);

      return;
    }

    setFileLoading(false);
    navigate('/venues/highlights/menu');
  };

  const loadMenu = async () => {
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venue-has-menu/${id}`)
      .catch(() => {});

    const responseData = get(response, 'data', {});
    if (!responseData.success) {
      setLoading(false);

      return;
    }
    const menuData = get(responseData, 'output', []);

    setMenuDetails(menuData);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      loadMenu();
    }

    return () => {
      setLoading(false);
    };
  }, [id]);

  return (
    <Layout
      key="venuesProfile"
      title="Venues Profile"
      breadcrumb={[
        <Link to="/">Home</Link>,
        <Link to="/venues/highlights">Highlights</Link>,
        <Link to="/venues/highlights/menu">Menu</Link>,
        'Create'
      ]}
    >
      <div className={fileLoading || loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <div className="dashboard-index align-items-start">
        <div className="highlights-detail__form col-12 mt-5 mt-md-0">
          <div className="row">
            <div className="col-12">
              <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" multiple type="file" />

              <form>
                <div className="row">
                  <div className="col-md-8">
                    {error.length > 0 &&
                      error.map((item, index) => (
                        <p key={index} className="error">
                          {item.message}
                        </p>
                      ))}
                    {id && menuDetails ? (
                      <>
                        {' '}
                        <div className="content-uploaded">
                          {menuDetails._misc.media.type === 'application/pdf' ? (
                            <>
                              <a className="pdf" href={menuDetails._misc.media.url} target="_blank" rel="noreferrer">
                                <img src="/images/icons/pdf_ico.svg" alt="pdf icon" />
                                Click here to view
                              </a>
                            </>
                          ) : (
                            <>
                              <img src={menuDetails._misc.media.original_image} alt="menu" width="100%" />
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <fieldset>
                      <label>Select File</label>
                      {!isNil(mssgUploadProgressBanner.progress) && (
                        <Progress
                          className="banner__progress-overlay"
                          value={mssgUploadProgressBanner.progress}
                          color={mssgUploadProgressBanner.color}
                        >
                          <span className="text-dark">{mssgUploadProgressBanner.progress}%</span>
                        </Progress>
                      )}
                      <input type="file" accept="application/pdf" id="file" name="file" onChange={e => setFile(e)} />
                      {id ? '' : validator.current.message('file', file, 'required')}
                    </fieldset>
                  </div>

                  <div className="col-md-3 offset-md-1">
                    <button type="submit" className="btn btn-primary w-100 mt-4" onClick={e => handleFileSubmit(e)}>
                      {id ? 'Update' : 'Create'}
                    </button>
                    <Link to="/venues/highlights/menu/" className="btn btn-outline-light my-2 w-100">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MenuForm;
