/* eslint-disable camelcase */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';

// Relatives
import AddressLookup from '../../../components/AddressLookup';

const FundraiserStep2 = props => {
  const { updateMode } = props;
  const { control, setValue, getValues } = useFormContext();
  const [newEditor, setNewEditor] = useState(updateMode);

  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');

      return;
    }

    const {
      formatted_address,
      streetNumber,
      streetName,
      postalCode,
      stateName,
      locality,
      occupancyIdentifier,
      countryCode
    } = address;

    setValue('country', countryCode);
    setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    setValue('street_address_2', occupancyIdentifier);
    setValue('city', locality);
    setValue('state', stateName);
    setValue('zip', postalCode);

    fieldOnChange(formatted_address);
  };

  const handleBlurFromDate = value => {
    const toDate = getValues('date_to');
    if (!toDate) {
      setValue('date_to', moment(value, 'MM/DD/YYYY', true).add('1', 'hour').toDate());
    }
  };

  const handleIsManualMode = isManual => {
    if (isManual === true) {
      setValue('address_manual_mode', true);
    }

    if (!isManual) {
      setValue('address_manual_mode', false);
    }
  };

  return (
    <>
      <Grid className={newEditor ? 'd-flex' : 'visually-hidden'} item xs={12}>
        <Grid
          onClick={() => {
            setNewEditor(false);
          }}
          item
          xs={12}
        >
          <Controller
            name={newEditor ? 'formatted_address' : ''}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                label="Address"
                variant="standard"
                style={{ color: '#fff' }}
                disabled
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid className={newEditor ? 'visually-hidden' : 'show'} item xs={12}>
        <Controller
          name={newEditor ? '' : 'formatted_address'}
          control={control}
          render={({ field, fieldState }) => {
            const { value, onChange } = field;

            return (
              <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Address
                </label>
                <AddressLookup
                  onChangeMode={handleIsManualMode}
                  onChange={handleChange(onChange)}
                  value={value}
                  placeholder="Write your address..."
                >
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'street_address'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Address is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address"
                          variant="standard"
                          placeholder="Street Address or P.O. Box"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'street_address_2'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address 2"
                          variant="standard"
                          placeholder="Apt, Suite, Unit Building (optional)"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex">
                    <Controller
                      name={newEditor ? '' : 'city'}
                      control={control}
                      rules={{ required: newEditor ? false : 'City is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="City"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={newEditor ? '' : 'state'}
                      control={control}
                      rules={{ required: newEditor ? false : 'State is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="State"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={newEditor ? '' : 'zip'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Zip is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Zip"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={newEditor ? '' : 'country'}
                      control={control}
                      rules={{ required: newEditor ? false : 'Country is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Country"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </AddressLookup>
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            );
          }}
        />
      </Grid>
      <Typography color="primary" variant="h1" className="text-center mx-auto">
        Date & Time
      </Typography>
      <Grid item xs={12} className="d-flex">
        <Controller
          name="date_base"
          control={control}
          rules={{
            required: 'From is required',
            validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                label="Date"
                minDate={new Date()}
                format="MM/dd/yyyy"
                clearable
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_from"
          control={control}
          rules={{
            required: 'From is required'
            /* validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            } */
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                label="From"
                minDate={new Date()}
                onAccept={handleBlurFromDate}
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_to"
          control={control}
          rules={{
            required: 'To is required'
            /* validate: {
              dateInvalid: value => {
                const momentDate = moment(value, 'MM/DD/YYYY', true);
                const diff = momentDate.diff(moment(new Date().setUTCHours(0, 0, 0, 0)));
                const diffFrom = momentDate.diff(getValues('date_from'));

                return diff < 0 || diffFrom < 0 || !momentDate.isValid() ? 'This date is invalid' : true;
              }
            }  */
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                label="To"
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                minDate={new Date()}
                invalidDateMessage="End date is required"
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </>
  );
};

FundraiserStep2.defaultProps = {
  updateMode: false
};

FundraiserStep2.propTypes = {
  updateMode: PropTypes.bool
};

export default FundraiserStep2;
