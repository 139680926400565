/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
/* import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram'; */
// import mtz from 'moment-timezone';
import { useNavigate } from 'react-router-dom';


/* Relatives */
import randomID from '../../../utils/randomID';
// import httpext from '../../../services/api/httpext';

/* Assets */
import LocationIcon from '../../../styles/images/icons/location_icon.png';
// import ClockIcon from '../../../styles/images/icons/clock_icon.png';
import WorldIcon from '../../../styles/images/icons/world_icon.png';
import MailIcon from '../../../styles/images/icons/message_icon.png';
import PhoneIcon from '../../../styles/images/icons/phone_icon.png';



const CardDetailsVenuesType1 = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const {locationAddress, website,  email,  phone, listschedule, editor} = props;
    // const { facebook, twitter, instagram } = socials;
    const [editable, setEditable] = useState('v-hidden');
    const [sheduleList, setShedulelist] = useState([]);
    // const [timezone, setTimezone] = useState('loading...');
    // const googleHost = 'https://maps.googleapis.com/maps/api';
    // const googleApiKey = 'AIzaSyCs-S2P3VcrlPBcFGWBF7CwhBUurT7KV1c';

    const handleClickUpdateUserProfile = () => {
      navigate(`/venues/profile/update/operationhours`);
    };

    // Getting timezone using the address to get geometry data and then timezone data.
    /* const timezonebyaddress = async () => {
            const response = await httpext(googleHost).get(`/timezone/json?locat
            ion=${geometry.lat}%2C${geometry.lng}&timestamp=1331161200&key=${googleApiKey}`);
            const timeZoneFormated = mtz.tz(response.data.timeZoneId).format("Z z");
            setTimezone(timeZoneFormated);

        return false;
    } */

    function SubListSheduleJsx(sub) {
        return sub.map(item => <p key={`item-sub_${randomID()}`}>{ item.dateTo } TO { item.dateEnd }</p>);
    }
    const ListScheduleJsx = sheduleList.map(shedule => (
        <div key={`Shedule-item_${randomID()}`} className={`d-flex Shedule-item Shedule-item_${randomID()}`}>
                <div key={`item-head_${randomID()}`}
                className={`Shedule-list--title Shedule-list--title_${randomID()} text-capitalize`}>{ shedule.title }</div>
                <div key={`item-body_${randomID()}`} className='Shedule-list--all-items text-uppercase'>
                    {SubListSheduleJsx(shedule.details)}
                </div>
        </div>
    ));

    /* const showFacebook = facebook !== '' ? <a href={facebook.url} target="_blank" rel='noreferrer'
    title={`Follow on Facebook ${facebook.name}`}><FacebookIcon /> <span>{facebook.name}</span></a> : '';
    const showTwitter = twitter !== '' ? <a href={twitter.url} target="_blank" rel='noreferrer'
    title={`Follow on Twitter ${facebook.name}`}><TwitterIcon /> <span>{twitter.name}</span></a> : '';
    const showInstagram = instagram !== '' ? <a href={instagram.url} target="_blank" rel='noreferrer'
    title={`Follow on Instagram ${facebook.name}`}><InstagramIcon /> <span>{instagram.name}</span></a> : ''; */

    useEffect(() => {
        setShedulelist(listschedule.datesList);
        // timezonebyaddress();
        if(editor){
            setEditable('btn-link');
        }
    });

    return(
        <div ref={ref} className='CardDetailsVenuesType1'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='CardDetailsVeneusType1-heading'>
                        <p className='d-flex flex-row'><img className='icon' src={LocationIcon} /> <span>{locationAddress}</span></p>
                        {email !== '' &&
                            <p><img className='icon' src={MailIcon} /> <a className='text-decoration-none text-white' href={`mailto:${email}`}>{email}</a></p>
                        }
                        {website !== '' &&
                            <p><img className='icon' src={WorldIcon} /> <a className='text-decoration-none text-white' href={`${website}`}>{website}</a></p>
                        }
                        {phone.length > 8 &&
                            <p><img className='icon' src={PhoneIcon} /> <a className='text-decoration-none text-white' href={`tel:${phone}`}>{phone}</a></p>
                        }
                        {/* <div className='CardDetailsVeneusType1-heading__socials'>
                    <h5>Socials</h5>
                    <div className='CardDetailsVeneusType1-heading__socials__list'>
                      {showFacebook}
                      {showTwitter}
                      {showInstagram}
                    </div>
                </div> */}
                    </div>
                </div>
                <div className='col-md-6'>
                    <Button onClick={() => {
                            handleClickUpdateUserProfile();
                        }} className={`btn btn-outline-primary mt-3 mt-md-0 ${editable}`}>
                            Edit Business Hours
                        </Button>
                    <div key={`Details_${randomID()}`} className='CardDetailsVeneusType1-body  mt-md-0'>
                        {ListScheduleJsx}   
                    </div>
                </div>
            </div>
        </div>
    );
});

CardDetailsVenuesType1.defaultProps = {
    editor: true,
    locationAddress: 'Largo Picadeiro 10 a 12, 1200-330 Lisboa',
    geometry: {
      lat: 0,
      lng: 0
    },
    website: 'https://picklejar.com',
    phone: '+1',
    email: 'xxxxxxxxx@gmail.com',
    hours: 'Edit business hours',
    socials: {
        facebook: {
          url: "#",
          name: "Neon1990",
          status: true
        },
        twitter: {
          url: "#",
          name: "Neon1990",
          status: true
        },
        instagram: {
          url: "#",
          name: "Neon1990",
          status: true
        }
    },
    listschedule: {
        datesList: [
            {
                title: 'Monday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Tuesday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Wednesday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Thursday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Friday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Saturday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            },
            {
                title: 'Sunday',
                details: [
                    {
                        dateTo: '9:00 AM',
                        dateEnd: '11:00 AM'
                    },
                    {
                        dateTo: '12:00 AM',
                        dateEnd: '12:00 AM'
                    }
                ]
            }
        ]
    }
}

CardDetailsVenuesType1.propTypes = {
    editor: PropTypes.bool,
    locationAddress: PropTypes.string,
    geometry: PropTypes.object,
    website: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    hours: PropTypes.string,
    socials: PropTypes.object,
    listschedule: PropTypes.object
}

export default CardDetailsVenuesType1;
