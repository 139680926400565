// Packages
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import get from 'lodash/get';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import PieChartCustom from '../../components/PieChartCustom/PieChartCustom';

const CampaignMoney = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}`)
      .catch(() => {});

    const responseData = get(response, 'data', {});
    if (!responseData.success) {
      setLoading(false);
      console.warn(responseData.error);

      return;
    }

    setEvent(responseData.output);
    const response2 = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}/stats`)
      .catch(() => {});

    setLoading(false);
    const response2Data = get(response2, 'data', {});
    if (!response2Data.success) {
      console.warn('error');

      return;
    }

    setStats(response2Data.output);
  };

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }),
    []
  );

  useEffect(() => {
    setLoading(true);
    loadModel();

    return () => {
      setLoading(false);
    };
  }, [id]);

  const ownerHandle = useMemo(() => {
    const eventOwners = get(event, 'eventOwners', []);

    return get(
      eventOwners.filter(owner => owner.type === 'OWNER_TYPE_NGO'),
      '0.handle',
      ''
    );
  }, [event]);

  if (loading || !stats || !event) {
    return null;
  }

  const {
    tips,
    tips_amount: tipsAmount,
    goal_amount: goalAmount,
    tips_average_percentage: tipsAveragePercentage,
    last_10_tip_senders: tippers
  } = stats;

  const data = [];
  const colors = [];
  if (goalAmount > 0) {
    if (tipsAmount > 0) {
      data.push({ name: 'Earned', value: tipsAmount, label: formatter.format(tipsAmount) });
      colors.push('#FF9900');
    }

    data.push({ name: 'Goal', value: goalAmount, label: formatter.format(goalAmount) });
    colors.push('#0088FE');
  }

  return (
    <Layout
      breadcrumb={[
        <Link to="/">Home</Link>,
        <Link to="/campaigns">Campaigns</Link>,
        <Link to={`/campaigns/${id}`}>{event.name}</Link>,
        'Campaign Progress'
      ]}
    >
      <div className="campaign-money">
        <h1>CAMPAIGN PROGRESS</h1>
        <div className="money-container d-flex col-sm-12 col-lg-10 col-xl-8 mx-auto flex-column">
          <div className="money-chart d-flex">
            <div className="card">
              <div className="card__header">TOTAL RAISED</div>
              <div className="card__body">
                {data.length > 0 && (
                  <PieChartCustom
                    data={data}
                    colors={colors}
                    chartWidth={300}
                    chartHeight={300}
                    innerRadius={140}
                    outerRadius={160}
                    cy="100%"
                  />
                )}
                {data.length === 0 && <div>Target Goal not defined</div>}
              </div>
            </div>
          </div>
          <div className="money-container__left d-flex flex-column flex-grow-1">
            <div className="d-flex">
              <div className="card">
                <div className="card__header">ASSOCIATION HANDLE</div>
                <div className="card__body">{ownerHandle}</div>
              </div>
              <div className="card">
                <div className="card__header">TOTAL TIPS</div>
                <div className="card__body">{tips}</div>
              </div>
            </div>
            <div className="d-flex">
              <div className="card">
                <div className="card__header">TOTAL AMOUNT EARNED</div>
                <div className="card__body">{formatter.format(tipsAmount)}</div>
              </div>
            </div>
            <div className="d-flex">
              <div className="card">
                <div className="card__header">TOTAL TO RAISE</div>
                <div className="card__body">{formatter.format(goalAmount)}</div>
              </div>
              <div className="card">
                <div className="card__header">% EACH PARTICIPANT</div>
                <div className="card__body">{tipsAveragePercentage}%</div>
              </div>
            </div>
            <div className="d-flex">
              <div className="card no-padding">
                <div className="card__header">LATEST CONTRIBUTORS</div>
                <div className="card__body">
                  <div className="money__tippers">
                    {tippers.map(({ sender: { handle, avatar_medium: avatarMedium }, amount_tipped: amount }, i) => (
                      <div key={i} className="tipper">
                        <div className="tipper__details">
                          <div
                            className="tipper__avatar"
                            style={{ backgroundImage: avatarMedium || null }}
                          />
                          <div className="tipper__label">{handle}</div>
                        </div>
                        <div className="tipper__amount">{formatter.format(amount)}</div>
                      </div>
                    ))}
                    {tippers.length === 0 && <div className="tippers__empty">No contributions yet</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CampaignMoney;
