// Packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { IconButton, Menu, MenuItem /* , Badge */ } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { AccountCircle, Logout } from '@mui/icons-material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// Relatives
import UserContext from '../../../contexts/UserContext';

const ProfileMenu = props => {
  const { onClose, isMobile, parentRef, isOpen, onSelectNGO, ngos } = props;
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClickNGO = id => () => onSelectNGO(id);

  const handleClickNewVenue = () => {
    navigate('/register/venues/create');
  };

  const handleClickUpdateUserProfile = () => {
    navigate('/user/profile/update');
  };

  // console.log(ngos, isMobile);

  const MobileMenu = [
    // <MenuItem onClick={onClose} key="1">
    //   <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
    //     <Badge badgeContent={17} color="error">
    //       <NotificationsIcon />
    //     </Badge>
    //   </IconButton>
    //   <span>Notifications</span>
    // </MenuItem>,
    // <MenuItem onClick={onClose} key="2">
    //   <IconButton size="large" aria-label="show 4 new mails" color="inherit">
    //     <Badge badgeContent={4} color="error">
    //       <AccountCircle />
    //     </Badge>
    //   </IconButton>
    //   <span>Messages</span>
    // </MenuItem>,
    <div key="overmenu" className='overflow-menu'>
        {[...ngos].map(ngo => (
      <MenuItem onClick={handleClickNGO(ngo.id)} key={ngo.id}>
        <IconButton size="large" color="inherit">
          <SwapHorizIcon />
        </IconButton>
        <span className='handle-label'>{ngo.name}</span>
      </MenuItem>
    ))}
    </div>,
    <MenuItem onClick={() => {
      handleClickNewVenue()
    }} key="3">
    <IconButton size="large" color="inherit">
      <AddCircleIcon />
    </IconButton>
    <div className="update-ngo-list-item">
      <span>Create a New Venue</span>
    </div>
  </MenuItem>,
  <MenuItem onClick={handleClickUpdateUserProfile} key="menu_3">
  <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="primary-search-account-menu"
    aria-haspopup="true"
    color="inherit"
  >
    <AccountCircle />
  </IconButton>
  <span>Update Member</span>
</MenuItem>,
    <MenuItem onClick={logout} key="menu_4">
      <IconButton size="large" color="inherit">
        <Logout />
      </IconButton>
      <span>Logout</span>
    </MenuItem>
  ];

  if (isMobile) {
    return MobileMenu;
  }

  return (
    <Menu
      className="layout-header-menu"
      anchorEl={parentRef.current}
      id="primary-search-account-menu-mobile"
      keepMounted
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li className="menu-header text-center">
        <strong>User Options</strong>
      </li>
      {MobileMenu}
    </Menu>
  );
};

ProfileMenu.defaultProps = {
  parentRef: null,
  ngos: [],
  isMobile: false,
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  onSelectNGO: noop
};

ProfileMenu.propTypes = {
  parentRef: PropTypes.object,
  ngos: PropTypes.array,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSelectNGO: PropTypes.func
};

export default ProfileMenu;
