import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import AsyncSelect from 'react-select/async';
import omit from 'lodash/omit';
import classNames from 'classnames';

// Relatives
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import http from '../../services/api/http';

const AddressLookup = props => {
  const { manualMode: manualModeProp, placeholder, onChange,
    onChangeMode, value, children, required } = props;
  const [manualMode, setManualMode] = useState(manualModeProp);
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setManualMode(!manualMode);
    onChangeMode(!manualMode);
  };

  const handleChange = selectedOption => {
    if (!selectedOption) {
      onChange(null);

      return;
    }

    const { value, optionals } = selectedOption;
    if (optionals) {
      onChange({ formatted_address: value, manualMode,  ...optionals });

      return;
    }

    onChange({ formatted_address: value });
  };

  const promiseOptions = inputValue =>
    new Promise(async resolve => {
      if (!inputValue) {
        resolve([]);

        return;
      }

      setLoading(true);
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/geocode/default/autocomplete?q=${inputValue}&required=${required}`)
        .catch(() => {});

      setLoading(false);
      const { success, output } = get(response, 'data', { success: false, data: [] });
      if (!success) {
        resolve([]);

        return;
      }

      const result = output.reduce(
        (acum, address) => [
          ...acum,
          {
            value: get(address, 'formattedAddress'),
            label: get(address, 'formattedAddress'),
            optionals: omit(address, ['formattedAddress'])
          }
        ],
        []
      );

      resolve(result);
    });

  const formatCreateLabelMemo = useCallback(text => text, []);

  const valueMemo = useMemo(() => {
    if (!value) {
      return null;
    }

    return { value, label: value };
  }, [value]);

  return (
    <div className={classNames('address-lookup', { 'lookup--model-manual': manualMode })}>
      {!manualMode && (
        <AsyncSelect
          className="address-lookup__search-input input--autocomplete"
          classNamePrefix="react-select"
          value={valueMemo}
          onChange={handleChange}
          placeholder={placeholder}
          isClearable
          isSearchable
          loadOptions={promiseOptions}
          // menuIsOpen
          // defaultOptions={[{value:'abc',label:'abc'},{value:'abc2',label:'abc2'},{value:'abc3',label:'abc3'}]}
          // readOnly={readOnly}
          defaultOptions
          components={{ DropdownIndicator: null }}
          // allowCreateWhileLoading
          formatCreateLabel={formatCreateLabelMemo}
        />
      )}
      <button type="button" className="btn btn-primary" onClick={handleClick} disabled={loading}>
        {manualMode ? 'Lookup Mode' : 'Manual Mode'}
      </button>
      {manualMode && <div className="address-lookup__manual-mode">{children}</div>}
    </div>
  );
};

AddressLookup.defaultProps = {
  children: null,
  value: '',
  required: 'streetNumber,streetName,postalCode,city,stateName,stateCode,country,countryCode',
  manualMode: false,
  placeholder: '',
  onChange: noop,
  onChangeMode: noop
};

AddressLookup.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  required: PropTypes.string,
  manualMode: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onChangeMode: PropTypes.func
};

export default AddressLookup;
