/* eslint-disable arrow-parens */
/* eslint-disable no-sequences */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */

// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Container, Typography, CircularProgress, Stepper, Step, StepButton, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
// import pick from 'lodash/pick';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

// Relatives
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext/UserContext';
import VenuesStep1 from './steps/VenuesStep1';
import VenuesStep2 from './steps/VenuesStep2';
import VenuesStep3 from './steps/VenuesStep3';
import VenuesStep3Update from './steps/VenuesStep3Update';

const VenuesCreate = props => {
  const { onSubmit, updateMode, id, stepParam, multiCreateMode } = props;
  const { apiHost } = useContext(AppContext);
  const { token, refreshNGOs, status, userNGOSelected, logout } = useContext(UserContext);
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(updateMode);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [stepFromParam, setStepFromParam] = useState(stepParam);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [cache, setCache] = useState({});
  const [disableOnSubmit, setDisableOnSubmit] = useState(false);
  const navigate = useNavigate();

  const venuesForm = useForm({
    defaultValues: {
      // step 1
      name: '',
      formatted_address: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      phone_number: '',
      email: '',
      // step 2
      media_id: null,
      background_media_id: null,
      bio: '',
      headline: '',
      website: '',
      merchandise_url: '',
      // step 3
      operation_hours: []
    }
  });

  let steps;

  if (updateMode) {
    steps = [
      {
        label: 'Let Us About your Venue',
        content: <VenuesStep1 editor cache={cache} />
      },
      {
        label: 'Let Us Look At the Venue',
        content: <VenuesStep2 editor cache={cache} setCache={setCache} setUploadLoading={setUploadLoading} />
      },
      {
        label: 'Let Us your times of operations',
        content: <VenuesStep3Update cache={cache} />
      }
    ];
  } else {
    steps = [
      {
        label: 'Let Us About your Venue',
        content: <VenuesStep1 cache={cache} />
      },
      {
        label: 'Let Us Look At the Venue',
        content: <VenuesStep2 cache={cache} setCache={setCache} setUploadLoading={setUploadLoading} />
      },
      {
        label: 'Let Us your times of operations',
        content: <VenuesStep3 />
      }
    ];
  }

  const handleStep = step => async () => {
    if (await venuesForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (await venuesForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = async data => {
    if (activeStep !== steps.length - 1 && (await venuesForm.trigger())) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
      setStepFromParam('');
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    setDisableOnSubmit(true);

    if (!formData || !token) {
      setDisableOnSubmit(false);
      return;
    }

    // START: Temporary function helper for extracting or keep "is_active"
    const newOperationHours = [];
    const UpdateDataFormData = formData.operation_hours;
    console.log(formData.operation_hours);
    UpdateDataFormData.map(hour => {
      if (hour.is_active) {
        newOperationHours.push({
          day: hour.day,
          is_active: hour.is_active,
          hour_open: hour.hour_open,
          hour_close: hour.hour_close
        });
      }

      if (!hour.is_active) {
        newOperationHours.push({
          day: hour.day,
          hour_open: hour.hour_open,
          hour_close: hour.hour_close
        });
      }

      return;
    });

    formData.operation_hours = newOperationHours;
    formData.virtual_artist_handle = `@${user.params.userName}`;
    formData.virtual_artist_website = formData.website;

    if (formData.address_manual_mode === true) {
      formData.lat = null;
      formData.lon = null;

      // pre-data for formated address
      const address1 = formData.street_address.length > 0 ? `${formData.street_address}, ` : '';
      const address2 = formData.street_address_2.length > 0 ? `${formData.street_address_2}, ` : '';

      const formattedAddress = `${address1}${address2}${formData.city}, ${formData.state}, ${formData.zip}, ${formData.country}`;
      // new formated address
      formData.formatted_address = formattedAddress;
    }
    console.log('submit', user.params.userName);
    // END: Temporary function helper for extracting or keep "is_active"

    let response;

    if (!updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v2/venues', formData)
        .catch(() => {});
    }

    if (updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .patch(`/api/web/v2/venues/${userNGOSelected.id}`, formData)
        .catch();
    }

    setLoading(false);
    setDisableOnSubmit(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.values(output).forEach(element => {
        console.log(element);
        toast.error(element, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });

      let pageToBack = 0;
      Object.keys(output).forEach(field => {
        venuesForm.setError(field, { type: 'required', message: get(output, `${field}.0`) });
        if (
          [
            'name',
            'formatted_address',
            'street_address',
            'street_address_2',
            'city',
            'state',
            'zip',
            'country',
            'phone_number',
            'email'
          ].includes(field)
        ) {
          pageToBack = 0;
        } else if (
          [
            'media_id',
            'background_media_id',
            'bio',
            'headline',
            'website',
            'merchandise_url',
            'operation_hours'
          ].includes(field)
        ) {
          pageToBack = 2;
        }
      });
      setActiveStep(pageToBack);

      return;
    }

    // auto select a new venue
    if (multiCreateMode) {
      if (success) {
        refreshNGOs(true).then(() => {
          navigate('/reload');
        });
      }

      return;
    }

    if (updateMode) {
      console.log('from update');
      if (success) {
        navigate('/reload');
      }

      console.log(output);

      return;
    }

    onSubmit(formData);
    window.location.reload();
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venues/${id || userNGOSelected.id}`)
      .catch(() => {
        logout();
        setTimeout(() => {
          window.location.reload();
        }, 200);
      });

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);
      return;
    }

    const {
      name,
      city,
      state,
      zip,
      country,
      phone_number,
      email,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      formatted_address,
      media_id,
      banner_id,
      bio,
      headline,
      website,
      merchandise_url,
      operation_hours
    } = output;

    const imageOriginal = get(output, '_misc.avatar.original_image');
    const bannerOriginal = get(output, '_misc.banner.original_image');
    const operationHours = get(output, '_misc.operation_hours');

    venuesForm.reset({
      name,
      city,
      state,
      zip,
      country,
      phone_number,
      email,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      formatted_address,
      media_id,
      banner_id,
      bio,
      headline,
      website,
      merchandise_url,
      operation_hours
    });

    setCache({
      media_id: imageOriginal,
      banner_id: bannerOriginal,
      full_address: formatted_address,
      phone_number,
      operation_hours: operationHours
    });

    setLoading(false);
  };

  /* const loginCancel = () => {
    logout();
    navigate('/');
  }; */

  useEffect(() => {
    // console.log(activeStep, steps, steps.length);
    // dataOfPersonality(id);
    if (updateMode && userNGOSelected.id) {
      loadModel();
    }

    if (stepFromParam > 0 && activeStep !== stepFromParam) {
      setTimeout(() => {
        setActiveStep(stepFromParam);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (uploadLoading) {
      setLoading(true);
    }

    if (!uploadLoading) {
      setLoading(false);
    }
  }, [uploadLoading]);

  return (
    <div className="organisation common-bg">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container className="campaign-create" maxWidth="md">
        <Box sx={{ width: '100%  !important', margin: '16px' }}>
          <Stepper nonLinear activeStep={activeStep} className={`py-4 ${uploadLoading ? 'v-hidden' : 'show'}`}>
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <FormProvider {...venuesForm}>
              <Box component="form" onSubmit={venuesForm.handleSubmit(stepperSubmit)} autoComplete="off">
                <Grid className="d-flex flex-column" container>
                  <Typography color="primary" variant="h1" className="text-center">
                    {steps[activeStep].label}
                  </Typography>
                  {steps[activeStep].content}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="btn-forms"
                    disabled={activeStep === 0 || uploadLoading}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {status === 'active' && (
                    <Button
                      type="button"
                      variant="outlined"
                      className="btn-forms me-4"
                      onClick={() => {
                        navigate('/venues/profile');
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {activeStep + 1 === steps.length ? (
                    <Button disabled={disableOnSubmit} type="submit" variant="outlined" className="btn-forms">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      onClick={handleNext}
                      variant="outlined"
                      className="btn-forms"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            </FormProvider>
          </div>
        </Box>
      </Container>
      <Toaster />
    </div>
  );
};

VenuesCreate.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  multiCreateMode: false,
  stepParam: null,
  id: null,
  updateMode: false
};

VenuesCreate.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  multiCreateMode: PropTypes.bool,
  stepParam: PropTypes.number,
  id: PropTypes.number,
  updateMode: PropTypes.bool
};

export default VenuesCreate;
