// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, FormHelperText } from '@mui/material';
import { multiply, round, divide, isEqual, noop, get, isNil } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Progress, Spinner } from 'reactstrap';

// Relatives
import ImageCropInput from '../../../components/ImageCropInput';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import http from '../../../services/api/http';

import logo from '../../../styles/images/logo.png';
import banner from '../../../styles/images/campaignDefault.png';

const FundraiserStep4 = props => {
  const { setStepLoading, cache, setCache, setUploadLoading } = props;
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [mssgUploadProgressAvatar, setMssgUploadProgressAvatar] = useState({ text: null, color: 'dark' });
  const [mssgUploadProgressBanner, setMssgUploadProgressBanner] = useState({ progress: null, color: 'dark' });
  const { control, setError, setValue } = useFormContext();
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);

  // Progress Avatar
  const uploadProgressAvatar = data => {
    const uploadPercentProgress = round(multiply(divide(data.loaded, data.total), 100));
    const messageUploadOnProgress = `Avatar Uploading.. ${uploadPercentProgress}%`;
    const messageUploadEnd = `Uploaded 100%`;

    if(isEqual(data.loaded, data.total)){
          setMssgUploadProgressAvatar({ text: messageUploadEnd, color: 'success' });

          setTimeout(() => {
            setMssgUploadProgressAvatar({ text: null, color: 'dark' });
          }, 3000);

        return messageUploadEnd;
    }

    if(!isEqual(data.loaded, data.total)){
       setMssgUploadProgressAvatar({ text: messageUploadOnProgress, color: 'warning' });
       return messageUploadOnProgress;
    }


    return false;
}

// Progress Banner
const uploadProgressBanner = data => {
  const uploadPercentProgress = round(multiply(divide(data.loaded, data.total), 100));
  const messageUploadOnProgress = uploadPercentProgress;
  const messageUploadEnd = 100;

  if(isEqual(data.loaded, data.total)){
        setMssgUploadProgressBanner({ progress: messageUploadEnd, color: 'success' });

        setTimeout(() => {
          setMssgUploadProgressBanner({ progress: null, color: 'dark' });
        }, 3000);

      return messageUploadEnd;
  }

  if(!isEqual(data.loaded, data.total)){
     setMssgUploadProgressBanner({ progress: messageUploadOnProgress, color: 'warning' });
     return messageUploadOnProgress;
  }


  return false;
}

  const handleSubmit = async (callbackLoading, input, file, owner) => {
    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    callbackLoading(true);

    let response;

    // Banner
    if(owner === 1){
      response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' }, uploadProgressBanner)
      .post('/api/web/media/manage/upload', formData)
      .catch(() => {});
    }

    // Avatar
    if(owner === 2){
      response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' }, uploadProgressAvatar)
      .post('/api/web/media/manage/upload', formData)
      .catch(() => {});
    }

    callbackLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));

      return;
    }

    setValue(input, get(response, 'data.data.0.id', ''));
    setCache(state => ({ ...state, [input]: get(response, 'data.data.0.original') }));
  };

  useEffect(() => {
    setStepLoading(loadingAvatar || loadingBanner);
  }, [loadingAvatar, loadingBanner]);

  // Progress callback owner
  const onUpload = (callbackLoading, owner) => (name, data) => handleSubmit(callbackLoading, name, data.file, owner);

  let bannerLarge = banner;
  if (cache.banner_id) {
    bannerLarge = cache.banner_id;
  }

  let avatarLarge = logo;
  if (cache.media_id) {
    avatarLarge = cache.media_id;
  }

  // Progress
  useEffect(() => {

    if(!isNil(mssgUploadProgressAvatar.text) || !isNil(mssgUploadProgressBanner.progress)){
      setUploadLoading(true);
    }

    if(isNil(mssgUploadProgressAvatar.text) && isNil(mssgUploadProgressBanner.progress)){
      setUploadLoading(false);
    }

  }, [mssgUploadProgressAvatar.text, mssgUploadProgressBanner.progress]);

  return (
    <div className="event__header">
      <div className="header__banner" style={{ backgroundImage: `url(${bannerLarge})` }}>
      { !isNil(mssgUploadProgressBanner.progress) &&
        <Progress className='banner__progress-overlay' value={mssgUploadProgressBanner.progress} color={mssgUploadProgressBanner.color}>
          <span className='text-dark'>{mssgUploadProgressBanner.progress}%</span>
        </Progress>
      }
        <Controller
          name="banner_id"
          control={control}
          rules={{ required: 'Banner is required' }}
          render={({ field, fieldState }) => (
            <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <ImageCropInput
                name={field.name}
                onUpload={onUpload(setLoadingBanner, 1)}
                aspect={16 / 9}
                showPreview={false}
              />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </div>
      <div className="header__banner-details">
        <div
          className={classNames('event__avatar', { 'default-avatar': !cache.media_id })}
          style={{ backgroundImage: `url(${avatarLarge})` }}
        >
          { !isNil(mssgUploadProgressAvatar.text) &&
          <Spinner className='avatar__progress-overlay' color={mssgUploadProgressAvatar.color}>
            {mssgUploadProgressAvatar.text}
          </Spinner>
        }
          <Controller
            name="media_id"
            control={control}
            rules={{ required: 'Avatar is required' }}
            render={({ field, fieldState }) => (
              <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <ImageCropInput
                  name={field.name}
                  onUpload={onUpload(setLoadingAvatar, 2)}
                  aspect={1}
                  showPreview={false}
                />
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

FundraiserStep4.defaultProps = {
  setStepLoading: noop,
  cache: null,
  setCache: noop,
  setUploadLoading: noop
};

FundraiserStep4.propTypes = {
  setStepLoading: PropTypes.func,
  cache: PropTypes.object,
  setCache: PropTypes.func,
  setUploadLoading: PropTypes.func
};

export default FundraiserStep4;
