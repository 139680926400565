/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Alert } from 'reactstrap';
import { useForm } from 'react-hook-form';
// import { Button  } from '@mui/material';
import noop from 'lodash/noop';
import get from 'lodash/get';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Relatives
import ImageCropInput from '../../ImageCropInput';
import UserContext from '../../../contexts/UserContext';
import AppContext from '../../../contexts/AppContext';
import http from '../../../services/api/http';

const CommonModalUpload = props => {
  const { openState, onClose, settings, onUploaded, cache } = props;
  const { /* host, */ apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const user = useContext(UserContext);
  const { register } = useForm();
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [idLoaded, setIdLoaded] = useState('');
  // const [open, setOpen] = useState(openState);

  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async (callbackLoading, input, file) => {
    setIdLoaded('uploading');

    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    callbackLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' })
      .post('/api/web/media/manage/upload', formData)
      .catch(() => {});

    callbackLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      /* Object.keys(output).forEach(field => setError(field,
      { type: 'required', message: get(output, `${field}.0`) })); */

      return;
    }

    setIdLoaded(get(response, 'data.data.0.id', ''));
    // setCache(state => ({ ...state, [input]: get(response, 'data.data.0.original') }));
  };

  const handleSave = async () => {
    const formDataUpdate = [];
    formDataUpdate[settings.idName] = idLoaded;
    console.log('data', formDataUpdate);
    formDataUpdate.virtual_artist_handle = `@${user.params.userName}`;
    const response = await http(apiHost, token.accessToken, token.tokenType).patch(
      `/api/web/v2/venues/${userNGOSelected.id}`,
      Object.assign({}, formDataUpdate)
    );

    const { success } = get(response, 'data', {});

    if (!success) {
      setIdLoaded(null);
    }

    if (success) {
      handleClose();
    }
  };

  useEffect(() => {
    if (openState === false) {
      setIdLoaded(null);
    }
  });

  useEffect(() => {
    onUploaded(loadingAvatar);

    if (idLoaded > 0) {
      handleSave();
    }
  }, [loadingAvatar, idLoaded]);

  const onUpload = callbackLoading => (name, data) => handleSubmit(callbackLoading, name, data.file);

  // let avatarLarge = 'https://dummyimage.com/876x250/000/fff';
  /* if (cache[[settings.idName]]) {
    avatarLarge = `${host}${cache[settings.idName]}`;
  } */

  return (
    <Modal fade={false} centered fullscreen="md" isOpen={openState} toggle={() => handleClose()}>
      <ModalHeader toggle={() => handleClose()}>{settings.title}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          {idLoaded === null && (
            <div
              className={classNames('event__avatar new-upload__input', { 'default-avatar': !cache[settings.idName] })}
            >
              <ImageCropInput
                key="uploadinput"
                name={settings.idName}
                onUpload={onUpload(setLoadingAvatar)}
                aspect={settings.aspect}
                showPreview={false}
              />
              <input {...register(settings.idName)} type="hidden" value={idLoaded} />
            </div>
          )}
          {idLoaded === false && <Alert color="danger">Oops sorry, your image not uploaded, try again!</Alert>}
          {idLoaded === 'uploading' && <Alert color="warning">Uploading...</Alert>}
          {idLoaded > 0 && !idLoaded !== 'uploading' && <Alert color="success">Image uploaded successfully!</Alert>}
        </form>
      </ModalBody>
    </Modal>
  );
};

CommonModalUpload.defaultProps = {
  openState: false,
  cache: {
    media_id: 322
  },
  onClose: noop,
  onUploaded: noop,
  settings: {
    title: 'Upload Your Image',
    idName: 'media_id',
    aspect: 1
  }
};

CommonModalUpload.propTypes = {
  openState: PropTypes.bool,
  cache: PropTypes.object,
  onClose: PropTypes.func,
  onUploaded: PropTypes.func,
  settings: PropTypes.object
};

export default CommonModalUpload;
