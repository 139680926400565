// Packages
import React from 'react';
import { Box, Button, Container, List, ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const Welcome = () => {
  const navigate = useNavigate();
  const selectOption = type => navigate(`/${type}`);

  // localStorage.setItem('tracklogin', 'notlogged');
  return (
    <Box className="Welcome text-center">
      <img src="./images/back.jpg" alt="register background" className="background" />
      <Container maxWidth="sm">
        <div>
          <img className="welcome-logo" src="/images/pjl.png" alt="logo" />
        </div>
        <div>
          <img className="welcome-logo-influencers" src="/images/logos/logo_venue_new.png" alt="logo" />
        </div>
        <Typography color="primary" variant="h1">
          Hey There!
        </Typography>
        <Typography>Please select an option</Typography>
        <List>
          <ListItem>
            <Button
              type="button"
              onClick={() => {
                selectOption('login');
                // localStorage.setItem('tracklogin', 'login');
              }}
              color="primary"
              size="large"
              variant="outlined"
              fullWidth
            >
              Login
            </Button>
          </ListItem>
          <ListItem>
            <Button
              type="button"
              onClick={() => {
                selectOption('register');
                // localStorage.setItem('tracklogin', 'register');
              }}
              color="primary"
              size="large"
              variant="contained"
              fullWidth
            >
              Register
            </Button>
          </ListItem>
        </List>
      </Container>
    </Box>
  );
};

export default Welcome;
