/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMediaQuery, Link } from '@mui/material';


// Relatives
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Collapse, Card, CardBody } from 'reactstrap';

const DropDown = props => {

  const { title, mainLinkName, left, listUrls } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(min-width: 1400px)');
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(open ? false : true);
  }

  const handleLink = url => {
    navigate(url);
  }

  return(
    <section className={!isMobile ? 'w-100' : 'w-auto'} >
    { isMobile &&
      <Dropdown isOpen={open} toggle={() => {
        handleToggle();
      }}>
      <DropdownToggle className='btn-link__dropdown'>
        {mainLinkName} <KeyboardArrowDownIcon />
      </DropdownToggle>
        <DropdownMenu style={{ marginLeft: `${left}rem` }} container="body">
          <span className='Dropdown__title'>{title}</span>
          { listUrls.length > 0 &&
            listUrls.map(link => (
              <DropdownItem className={`${link.state ? 'disabled' : ''}`} onClick={() => {
                handleLink(link.url);
              }}>
                {link.name}
                {link.comingsoon &&
                  <small className='tag-comingsoon'>Coming Soon</small>
                }
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    }
    { !isMobile &&
    <div>
    <Link
      className='nav-link text-decoration-none m-0'
      onClick={() => {
        handleToggle()
      }}
      style={{
        marginBottom: '1rem'
      }}
    >
      {mainLinkName} <KeyboardArrowDownIcon />
    </Link>
    <Collapse className='bg-transparent' isOpen={open}>
      <Card className='bg-transparent' style={{ width: '100%' }}>
        <CardBody>
        { listUrls.length > 0 &&
            listUrls.map(link => (
              <a className={`nav-link ${link.state ? 'disabled' : ''}`} onClick={() => {
                handleLink(link.url);
              }}>
                {link.name}
                {link.comingsoon &&
                  <small style={{ right: '10px' }} className='tag-comingsoon mt-2'>Coming Soon</small>
                }
              </a>
            ))
          }
        </CardBody>
      </Card>
    </Collapse>
  </div>
    }
    </section>
  )

}

DropDown.defaultProps = {
  title: 'Title',
  mainLinkName: 'Picklejar Main Link',
  left: 0,
  listUrls: [
    {
      name: 'Picklejar First Link',
      url: '#',
      state: false,
      comingsoon: false
    },
    {
      name: 'Picklejar Second Link 2',
      url: '#',
      state: false,
      comingsoon: false
    }
  ]
}

DropDown.propTypes = {
  title: PropTypes.string,
  mainLinkName: PropTypes.string,
  left: PropTypes.number,
  listUrls: PropTypes.array
}

export default DropDown;
