/* eslint-disable operator-linebreak */
// Packages
import React, { useMemo } from 'react';
// import PropTypes from 'prop-types';
// import noop from 'lodash/noop';
import { MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';

import DropDown from '../../../components/common/dropdown/DropDown';

const NavigationMenu = () => {
  const navigation = useMemo(() => [
    { link: '/dashboard', name: 'Dashboard', state: true, comingsoon: false },
    { link: '/artist-pay-now', name: 'Pay Artist', state: true, comingsoon: false },
    { link: '/events', name: 'Events', state: false, comingsoon: true },
    { link: '/schedule', name: 'Schedule', state: false, comingsoon: true },
    { link: '/messenger/direct', name: 'Messenger', state: false, comingsoon: true },
    { link: '/reports', name: 'Reports', state: false, comingsoon: true },
    { link: '/awards', name: 'Awards', state: false, comingsoon: true },
    { link: '/advertising', name: 'Advertising', state: false, comingsoon: true }
  ]);

  const RulesDropDown = useMemo(() => [
    {
      mainLinkName: 'Events',
      mainLinkUrl: '#',
      left: -6,
      title: 'Ge it happening',
      listUrls: [
        {
          name: 'Regular Event',
          url: '/events',
          state: false,
          comingsoon: false
        },
        {
          name: 'Campaigns',
          url: '/campaigns',
          state: false,
          comingsoon: false
        },
        {
          name: 'Fundraisers',
          url: '/fundraisers',
          state: false,
          comingsoon: false
        }
      ]
    }
  ]);

  const getMenuDropDown = find => {
    console.log(
      RulesDropDown.filter(menu => menu.mainLinkName === find),
      'TAO'
    );
    return RulesDropDown.find(menu => menu.mainLinkName === find);
  };

  return (
    <ul>
      {navigation.map((menuItem, index) => (
        <MenuItem key={`menu-item-${index}`}>
          {getMenuDropDown(menuItem.name) && (
            <DropDown
              title={getMenuDropDown(menuItem.name).title}
              comingsoon={getMenuDropDown(menuItem.name).comingsoon}
              state={getMenuDropDown(menuItem.name).state}
              mainLinkName={getMenuDropDown(menuItem.name).mainLinkName}
              mainLinkUrl={getMenuDropDown(menuItem.name).mainLinkUrl}
              left={getMenuDropDown(menuItem.name).left}
              listUrls={getMenuDropDown(menuItem.name).listUrls}
            />
          )}

          {!getMenuDropDown(menuItem.name) && (
            <NavLink
              to={menuItem.link}
              className={({ isActive }) =>
                isActive
                  ? `nav-link selected ${menuItem.state ? 'available' : 'disabled'}`
                  : `nav-link ${menuItem.state ? 'available' : 'disabled'}`
              }
            >
              {menuItem.name}
              <br />
              <small className="tag-comingsoon">{menuItem.comingsoon ? 'Coming Soon' : ''}</small>
            </NavLink>
          )}
        </MenuItem>
      ))}
    </ul>
  );
};

NavigationMenu.defaultProps = {};

NavigationMenu.propTypes = {};

export default NavigationMenu;
