/* eslint-disable react/no-danger */
// Packages
import React from 'react';
import {
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

const ModalCountries = props => {
  const { openModal, onClose, onSelectCountry, className, countries } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleClick = (callingCode, flag) => () => {
    onSelectCountry(callingCode, flag);
    onClose(false);
  };

  return (
    <Modal
      className={classNames({ [className]: className })}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="sm">
        <div className="modal-header">
          <Typography id="modal-modal-title" component="h6" className="title">
            Select your country
          </Typography>
          <CloseIcon className="close" onClick={handleClose} />
        </div>
        <div className="modal-body">
          <List>
            {countries.map(({ calling_code: callingCode, flag, name }, index) => (
              <ListItem disablePadding key={index}>
                <ListItemButton role={undefined} onClick={handleClick(callingCode, flag)}>
                  <ListItemAvatar>
                    <Avatar alt={flag} src={flag} />
                  </ListItemAvatar>
                  <ListItemText primary={callingCode} secondary={name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </Container>
    </Modal>
  );
};

ModalCountries.defaultProps = {
  className: '',
  countries: [],
  openModal: false,
  onSelectCountry: noop,
  onClose: noop
};

ModalCountries.propTypes = {
  className: PropTypes.string,
  countries: PropTypes.array,
  openModal: PropTypes.bool,
  onSelectCountry: PropTypes.func,
  onClose: PropTypes.func
};

export default ModalCountries;
