import React, { useContext } from 'react';
import { Dashboard } from '@picklejarapp/portal-dashboards';
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext/AppContext';
import Layout from '../../layouts/Layout';

export default function DashboardComponent(props) {
  const { id, token, getUser } = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType,
    id
  };
  // const { path } = useRouteMatch();
  const path = '/dashboard';
  return (
    <Layout title="Dashboard" breadcrumb={['Home', 'Dashboard']}>
      <div className="Dashboard">
        <Dashboard apiHost={apiHost} userDetails={userDetails} artistId={''} eventType={''} />
      </div>
    </Layout>
  );
}
