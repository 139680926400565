import { CircularProgress } from '@mui/material';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Progress } from 'reactstrap';
import { multiply, round, divide, isEqual, get, isNil } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';

import Layout from '../../../../layouts/Layout';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext';
import http from '../../../../services/api/http';

const CalendarForm = () => {
  const { id } = useParams();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate } }));
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [file, setFile] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [calenderDetails, setCalenderDetails] = useState(false);
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const [mssgUploadProgressBanner, setMssgUploadProgressBanner] = useState({ progress: null, color: 'dark' });

  const uploadProgressBanner = data => {
    const uploadPercentProgress = round(multiply(divide(data.loaded, data.total), 100));
    const messageUploadOnProgress = uploadPercentProgress;
    const messageUploadEnd = 100;

    if (isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadEnd, color: 'success' });

      setTimeout(() => {
        setMssgUploadProgressBanner({ progress: null, color: 'dark' });
      }, 3000);

      return messageUploadEnd;
    }

    if (!isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadOnProgress, color: 'warning' });
      return messageUploadOnProgress;
    }

    return false;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formValid = validator.current.allValid();

    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(true);

      return;
    }

    if (!token) {
      return;
    }

    const formData = new FormData();

    if (file) {
      formData.append('file', file.target.files[0]);
      setFileLoading(true);

      const response = await http(
        apiHost,
        token.accessToken,
        token.tokenType,
        { 'Content-Type': 'multipart/form-data' },
        uploadProgressBanner
      )
        .post('/api/web/media/manage/upload', formData)
        .catch(() => {});

      if (!response || !response.data) {
        setFileLoading(false);

        return;
      }

      const { success, output } = get(response, 'data', {});
      if (!success && output) {
        Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
        setFileLoading(false);

        return;
      }

      const mediaId = get(response, 'data.data.0.id', '');

      if (id) {
        updateCalender(mediaId);
      } else {
        createCalender(mediaId);
      }
    }

    if (id) {
      updateCalender(calenderDetails.media_id);
    }
  };

  const createCalender = async mediaId => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/venue-has-menu', {
        venue_id: userNGOSelected.id,
        media_id: mediaId,
        menu_type: 3,
        title,
        description,
        start_date: Math.floor(new Date(startDate).getTime() / 1000),
        end_date: Math.floor(new Date(endDate).getTime() / 1000),
        offer_type: 1
      })
      .catch(() => {});

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
      setFileLoading(false);
      return;
    }
    setFileLoading(false);
    navigate('/venues/highlights/calendar');
  };

  const updateCalender = async mediaId => {
    setFileLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .patch(`/api/web/v2/venue-has-menu/${id}`, {
        venue_id: userNGOSelected.id,
        media_id: mediaId,
        menu_type: 3,
        title,
        description,
        start_date: Math.floor(new Date(startDate).getTime() / 1000),
        end_date: Math.floor(new Date(endDate).getTime() / 1000),
        offer_type: 1
      })
      .catch(() => {});

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
      setFileLoading(false);
      return;
    }
    setFileLoading(false);
    navigate('/venues/highlights/calendar');
  };

  const loadCalender = async () => {
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venue-has-menu/${id}`)
      .catch(() => {});

    const responseData = get(response, 'data', {});
    if (!responseData.success) {
      setLoading(false);

      return;
    }
    const calanderData = get(responseData, 'output', []);

    setCalenderDetails(calanderData);
    updateCalenderData(calanderData);
    setLoading(false);
  };

  const updateCalenderData = data => {
    setTitle(data.title);
    setDescription(data.description);
    const startDate = new Date(data.start_date * 1000);
    const endDate = new Date(data.end_date * 1000);
    setStartDate(startDate.toISOString().slice(0, 10));
    setEndDate(endDate.toISOString().slice(0, 10));
  };

  useEffect(() => {
    if (id) {
      loadCalender();
    }

    return () => {
      setLoading(false);
    };
  }, [id]);

  return (
    <Layout
      key="venuesProfile"
      title="Venues Profile"
      breadcrumb={[
        <Link to="/">Home</Link>,
        <Link to="/venues/highlights">Highlights</Link>,
        <Link to="/venues/highlights/calendar">Calendar</Link>,
        'Create'
      ]}
    >
      <div className={fileLoading || loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <div className="dashboard-index align-items-start">
        <div className="highlights-detail__form col-12 mt-5 mt-md-0">
          <div className="row">
            <div className="col-12">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    {error.length > 0 &&
                      error.map((item, index) => (
                        <p key={index} className="error">
                          {item.message}
                        </p>
                      ))}
                    {id && calenderDetails ? (
                      <>
                        {' '}
                        <div className="content-uploaded">
                          {calenderDetails._misc.media.type === 'application/pdf' ? (
                            <>
                              <a
                                className="pdf"
                                href={calenderDetails._misc.media.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/images/icons/pdf_ico.svg" alt="pdf icon" />
                                Click here to view
                              </a>
                            </>
                          ) : (
                            <>
                              <img src={calenderDetails._misc.media.original_image} alt="menu" width="100%" />
                            </>
                          )}
                        </div>
                        <hr />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <fieldset>
                          <label>Title</label>
                          <input
                            type="text"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            onBlur={() => validator.current.showMessageFor('title')}
                          />
                          {validator.current.message('title', title, 'required')}
                        </fieldset>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <label>Description</label>
                          <textarea rows={2} value={description} onChange={e => setDescription(e.target.value)} />
                        </fieldset>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <label>Start Date</label>
                          <input
                            type="date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            onBlur={() => validator.current.showMessageFor('startDate')}
                          />
                          {validator.current.message('startDate', startDate, 'required')}
                        </fieldset>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <label>End Date</label>
                          <input
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            onBlur={() => validator.current.showMessageFor('endDate')}
                          />
                          {validator.current.message('endDate', endDate, 'required')}
                        </fieldset>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <label>Media</label>
                          {!isNil(mssgUploadProgressBanner.progress) && (
                            <Progress
                              className="banner__progress-overlay"
                              value={mssgUploadProgressBanner.progress}
                              color={mssgUploadProgressBanner.color}
                            >
                              <span className="text-dark">{mssgUploadProgressBanner.progress}%</span>
                            </Progress>
                          )}
                          <input type="file" accept="image/*" id="file" onChange={e => setFile(e)} />
                          {id ? '' : validator.current.message('file', file, 'required')}
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 offset-md-1">
                    <button type="submit" className="btn btn-primary w-100 mt-4" onClick={e => handleSubmit(e)}>
                      {id ? 'Update' : 'Create'}
                    </button>
                    <Link to="/venues/highlights/calendar/" className="btn btn-outline-light my-2 w-100">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CalendarForm;
