import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

// Assets
import MarkerIcon from '../../../styles/images/picklejar_venue_location.png';

const MapView = props => {
    const { center, zoom, styles, marker } = props;
    // console.log(center);
    return(
        <>
        { center.lat > 0 &&
          <LoadScript
            googleMapsApiKey="AIzaSyCs-S2P3VcrlPBcFGWBF7CwhBUurT7KV1c"
        >
            <GoogleMap
                mapContainerStyle={styles}
                center={center}
                zoom={zoom}
            >
              {marker &&
                <Marker  clickable title="Picklejar - Venue Location" icon={MarkerIcon} position={center} />
              }
            </GoogleMap>
        </LoadScript>
        }
        </>
    );
}

MapView.defaultProps = {
    center: {
        lat: 40.4406076,
        lng: -3.7052551
      },
    styles: {
        width: '100%',
        height: '200px',
        margin: '0rem 2rem',
        borderRadius: '4px'
      },
    zoom: 1,
    marker: false

}

MapView.propTypes = {
    center: PropTypes.object,
    styles: PropTypes.object,
    zoom: PropTypes.number,
    marker: PropTypes.bool
}

export default MapView;
