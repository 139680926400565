// Packages
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { CircularProgressbar } from 'react-circular-progressbar';

// Relatives
import ParticipantList from './ParticipantList';

// Style
import 'react-circular-progressbar/dist/styles.css';

const Campaign = forwardRef((props, ref) => {
  const { id, name, /* address, */ fromDate, toDate, participants, bgImage, avatarImage, goalProgress } = props;
  const bgStyle = {};
  if (bgImage) {
    bgStyle.backgroundImage = `url(${bgImage})`;
  }

  const avatarStyle = {};
  if (avatarImage) {
    avatarStyle.backgroundImage = `url(${avatarImage})`;
  }

  return (
    <div ref={ref} className="campaign">
      <div className="campaign__bg" style={bgStyle} />
      <div className="campaign__header">
        <div className="campaign__time">
          <div className="time__item">
            <div className="time__type">FROM</div>
            <div className="time__date">
              <Moment style={{ whiteSpace: 'pre' }} format={'MMM[\n]DD'}>
                {fromDate}
              </Moment>
            </div>
            <div className="time__time">
              <Moment format="HH:mm">{fromDate}</Moment>EST
            </div>
          </div>
          <div className="time__item">
            <div className="time__type">TO</div>
            <div className="time__date">
              <Moment style={{ whiteSpace: 'pre' }} format={'MMM[\n]DD'}>
                {toDate}
              </Moment>
            </div>
            <div className="time__time">
              <Moment format="HH:mm">{toDate}</Moment>
              <span>EST</span>
            </div>
          </div>
        </div>
        <div className="campaign__progress">
          <CircularProgressbar value={goalProgress} text={`${goalProgress}%`} />
        </div>
      </div>
      <div className="campaign__body" />
      <div className="campaign__footer">
        <div className="footer__left">
          <div className="campaign__owner">
            <div className="owner__avatar" style={avatarStyle} />
          </div>
          <div className="campaign__details">
            <div className="campaign__name">{name}</div>
            {/* <div className="campaign__address">{address}</div> */}
          </div>
        </div>
        <div className="footer__right">
          <div className="campaign__participants">
            {/* <div className="participants__title">Supporters</div> */}
            <ParticipantList items={participants} eventId={id} />
          </div>
        </div>
      </div>
    </div>
  );
});

Campaign.defaultProps = {
  id: 0,
  name: '',
  bgImage: '',
  avatarImage: '',
  address: '',
  fromDate: 0,
  toDate: 0,
  goalProgress: 0,
  participants: []
};

Campaign.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  bgImage: PropTypes.string,
  avatarImage: PropTypes.string,
  address: PropTypes.string,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  goalProgress: PropTypes.number,
  participants: PropTypes.array
};

export default Campaign;
