/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
// Packages
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';

const blank = '';

const VenuesStep3 = () => {
  const { register, setValue, getValues } = useFormContext();
  const today = moment(new Date()).format('YYYY-MM-DD');

  const [timeStart0, setTimeStart0] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd0, setTimeEnd0] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart1, setTimeStart1] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd1, setTimeEnd1] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart2, setTimeStart2] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd2, setTimeEnd2] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart3, setTimeStart3] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd3, setTimeEnd3] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart4, setTimeStart4] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd4, setTimeEnd4] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart5, setTimeStart5] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd5, setTimeEnd5] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  const [timeStart6, setTimeStart6] = useState(moment(new Date('2021-08-18T18:00:00')).format());
  const [timeEnd6, setTimeEnd6] = useState(moment(new Date('2021-08-18T02:00:00')).format());
  // Checkbox states
  const [check0, setCheck0] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);

  useEffect(() => {
    setValue('operation_hours[0].day', 1);

    if (check0) {
      setValue('operation_hours[0].is_active', check0);
    }
    setValue('operation_hours[0].hour_open', moment(timeStart0).format('hh:mm A'));
    setValue('operation_hours[0].hour_close', moment(timeEnd0).format('hh:mm A'));

    setValue('operation_hours[1].day', 2);

    if (check1) {
      setValue('operation_hours[1].is_active', check1);
    }
    setValue('operation_hours[1].hour_open', moment(timeStart1).format('hh:mm A'));
    setValue('operation_hours[1].hour_close', moment(timeEnd1).format('hh:mm A'));

    setValue('operation_hours[2].day', 3);

    if (check2) {
      setValue('operation_hours[2].is_active', check2);
    }
    setValue('operation_hours[2].hour_open', moment(timeStart2).format('hh:mm A'));
    setValue('operation_hours[2].hour_close', moment(timeEnd2).format('hh:mm A'));

    setValue('operation_hours[3].day', 4);

    if (check3) {
      setValue('operation_hours[3].is_active', check3);
    }
    setValue('operation_hours[3].hour_open', moment(timeStart3).format('hh:mm A'));
    setValue('operation_hours[3].hour_close', moment(timeEnd3).format('hh:mm A'));

    setValue('operation_hours[4].day', 5);

    if (check4) {
      setValue('operation_hours[4].is_active', check4);
    }
    setValue('operation_hours[4].hour_open', moment(timeStart4).format('hh:mm A'));
    setValue('operation_hours[4].hour_close', moment(timeEnd4).format('hh:mm A'));

    setValue('operation_hours[5].day', 6);

    if (check5) {
      setValue('operation_hours[5].is_active', check5);
    }
    setValue('operation_hours[5].hour_open', moment(timeStart5).format('hh:mm A'));
    setValue('operation_hours[5].hour_close', moment(timeEnd5).format('hh:mm A'));

    setValue('operation_hours[6].day', 7);

    if (check6) {
      setValue('operation_hours[6].is_active', check6);
    }
    setValue('operation_hours[6].hour_open', moment(timeStart6).format('hh:mm A'));
    setValue('operation_hours[6].hour_close', moment(timeEnd6).format('hh:mm A'));
  });

  useEffect(() => {
    if (getValues('operation_hours[0].hour_open') !== blank) {
      const newTimeStart0 = moment(new Date(`${today} ${getValues('operation_hours[0].hour_open')}`)).format();
      setTimeStart0(newTimeStart0);
    }

    if (getValues('operation_hours[0].hour_close') !== blank) {
      const newTimeClose0 = moment(new Date(`${today} ${getValues('operation_hours[0].hour_close')}`)).format();
      setTimeEnd0(newTimeClose0);
    }

    if (getValues('operation_hours[1].hour_open') !== blank) {
      const newTimeStart1 = moment(new Date(`${today} ${getValues('operation_hours[1].hour_open')}`)).format();
      setTimeStart1(newTimeStart1);
    }

    if (getValues('operation_hours[1].hour_close') !== blank) {
      const newTimeClose1 = moment(new Date(`${today} ${getValues('operation_hours[1].hour_close')}`)).format();
      setTimeEnd1(newTimeClose1);
    }

    if (getValues('operation_hours[2].hour_open') !== blank) {
      const newTimeStart2 = moment(new Date(`${today} ${getValues('operation_hours[2].hour_open')}`)).format();
      setTimeStart2(newTimeStart2);
    }

    if (getValues('operation_hours[2].hour_close') !== blank) {
      const newTimeClose2 = moment(new Date(`${today} ${getValues('operation_hours[2].hour_close')}`)).format();
      setTimeEnd0(newTimeClose2);
    }

    if (getValues('operation_hours[3].hour_open') !== blank) {
      const newTimeStart3 = moment(new Date(`${today} ${getValues('operation_hours[3].hour_open')}`)).format();
      setTimeStart3(newTimeStart3);
    }

    if (getValues('operation_hours[3].hour_close') !== blank) {
      const newTimeClose3 = moment(new Date(`${today} ${getValues('operation_hours[3].hour_close')}`)).format();
      setTimeEnd3(newTimeClose3);
    }

    if (getValues('operation_hours[4].hour_open') !== blank) {
      const newTimeStart4 = moment(new Date(`${today} ${getValues('operation_hours[4].hour_open')}`)).format();
      setTimeStart4(newTimeStart4);
    }

    if (getValues('operation_hours[4].hour_close') !== blank) {
      const newTimeClose4 = moment(new Date(`${today} ${getValues('operation_hours[4].hour_close')}`)).format();
      setTimeEnd4(newTimeClose4);
    }

    if (getValues('operation_hours[5].hour_open') !== blank) {
      const newTimeStart5 = moment(new Date(`${today} ${getValues('operation_hours[5].hour_open')}`)).format();
      setTimeStart5(newTimeStart5);
    }

    if (getValues('operation_hours[5].hour_close') !== blank) {
      const newTimeClose5 = moment(new Date(`${today} ${getValues('operation_hours[5].hour_close')}`)).format();
      setTimeEnd5(newTimeClose5);
    }

    if (getValues('operation_hours[6].hour_open') !== blank) {
      const newTimeStart6 = moment(new Date(`${today} ${getValues('operation_hours[6].hour_open')}`)).format();
      setTimeStart6(newTimeStart6);
    }

    if (getValues('operation_hours[6].hour_close') !== blank) {
      const newTimeClose6 = moment(new Date(`${today} ${getValues('operation_hours[6].hour_close')}`)).format();
      setTimeEnd0(newTimeClose6);
    }

    if (getValues('operation_hours[0].is_active')) {
      setCheck0(true);
    }

    if (getValues('operation_hours[1].is_active')) {
      setCheck1(true);
    }

    if (getValues('operation_hours[2].is_active')) {
      setCheck2(true);
    }

    if (getValues('operation_hours[3].is_active')) {
      setCheck3(true);
    }

    if (getValues('operation_hours[4].is_active')) {
      setCheck4(true);
    }

    if (getValues('operation_hours[5].is_active')) {
      setCheck5(true);
    }

    if (getValues('operation_hours[6].is_active')) {
      setCheck6(true);
    }
  }, []);

  return (
    <div className="flex-mobile">
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Monday
            <input {...register('operation_hours[0].day')} value="1" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[0].is_active')}
            onClick={() => {
              if (check0) {
                setCheck0(false);
              } else {
                setCheck0(true);
              }
            }}
            checked={check0}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[0].hour_open')}
              label="Open Hour"
              value={timeStart0}
              mask="__:__"
              ampm
              onChange={value => {
                setTimeStart0(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[0].hour_close')}
              label="Close Hour"
              value={timeEnd0}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart0)}
              onChange={value => {
                setTimeEnd0(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Tuesday
            <input {...register('operation_hours[1].day')} value="2" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[1].is_active')}
            onClick={() => {
              if (check1) {
                setCheck1(false);
              } else {
                setCheck1(true);
              }
            }}
            checked={check1}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[1].hour_open')}
              label="Open Hour"
              value={timeStart1}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeStart1(value);
                // console.log(value, 'time 1');
              }}
              clearable
              renderInput={props => (
                <TextField
                  onChange={value => {
                    setTimeStart1(value);
                    // console.log(value, 'time 1');
                  }}
                  {...props}
                  variant="standard"
                  className="flex-grow-1"
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[1].hour_close')}
              label="Close Hour"
              value={timeEnd1}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart1)}
              onChange={value => {
                setTimeEnd1(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Wednesday
            <input {...register('operation_hours[2].day')} value="3" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[2].is_active')}
            onClick={() => {
              if (check2) {
                setCheck2(false);
              } else {
                setCheck2(true);
              }
            }}
            checked={check2}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[2].hour_open')}
              label="Open Hour"
              value={timeStart2}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeStart2(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[2].hour_close')}
              label="Close Hour"
              value={timeEnd2}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart2)}
              onChange={value => {
                setTimeEnd2(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Thursday
            <input {...register('operation_hours[3].day')} value="4" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[3].is_active')}
            onClick={() => {
              if (check3) {
                setCheck3(false);
              } else {
                setCheck3(true);
              }
            }}
            checked={check3}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[3].hour_open')}
              label="Open Hour"
              value={timeStart3}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart3)}
              onChange={value => {
                setTimeEnd3(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[3].hour_close')}
              label="Close Hour"
              value={timeEnd3}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeEnd3(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Friday
            <input {...register('operation_hours[4].day')} value="5" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[4].is_active')}
            onClick={() => {
              if (check4) {
                setCheck4(false);
              } else {
                setCheck4(true);
              }
            }}
            checked={check4}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[4].hour_open')}
              label="Open Hour"
              value={timeStart4}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeStart4(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[4].hour_close')}
              label="Close Hour"
              value={timeEnd4}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart4)}
              onChange={value => {
                setTimeEnd4(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Saturday
            <input {...register('operation_hours[5].day')} value="6" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[5].is_active')}
            onClick={() => {
              if (check5) {
                setCheck5(false);
              } else {
                setCheck5(true);
              }
            }}
            checked={check5}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[5].hour_open')}
              label="Open Hour"
              value={timeStart5}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeStart5(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[5].hour_close')}
              label="Close Hour"
              value={timeEnd5}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart5)}
              onChange={value => {
                setTimeEnd5(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-evenly  align-items-baseline">
        <>
          <label className="w-15">
            Sunday
            <input {...register('operation_hours[6].day')} value="7" type="hidden" />
          </label>
          <Checkbox
            {...register('operation_hours[6].is_active')}
            onClick={() => {
              if (check6) {
                setCheck6(false);
              } else {
                setCheck6(true);
              }
            }}
            checked={check6}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              {...register('operation_hours[6].hour_open')}
              label="Open Hour"
              value={timeStart6}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              minTime={dayjs(timeStart6)}
              onChange={value => {
                setTimeEnd6(value.toDate());
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register('operation_hours[6].hour_close')}
              label="Close Hour"
              value={timeEnd6}
              mask="__:__"
              ampm
              inputFormat="hh:mm a"
              onChange={value => {
                setTimeEnd6(value);
              }}
              clearable
              renderInput={props => <TextField {...props} variant="standard" className="flex-grow-1" />}
            />
          </LocalizationProvider>
        </>
      </Grid>
    </div>
  );
};

VenuesStep3.defaultProps = {};

VenuesStep3.propTypes = {};

export default VenuesStep3;
