// Packages
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Container, TextField, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

// Relatives
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext/UserContext';
import MaskedInput from '../../../components/MaskedInput';

const UserProfile = props => {
  const { onSubmit, updateMode, id, onCancel } = props;
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(updateMode);
  const userProfileForm = useForm({
    defaultValues: {
      handle: '',
      firstName: '',
      lastName: ''
    }
  });

  const checkUsername = async username => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v1/search-handle/existing-performer-search', {
        handle: username
      })
      .catch(() => {});

    if (!response || !response.data) {
      return;
    }

    const { success, error } = get(response, 'data', {});

    userProfileForm.clearErrors('username');
    if (!success && error) {
      error.fieldErrors.forEach(({ field, message }) =>
        userProfileForm.setError(field, { type: 'checkUser', message })
      );
    } else {
      userProfileForm.clearErrors('handle');
    }
  };

  const checkUsernameDebounced = useRef(debounce(checkUsername, 250));

  const handleChange = async e => {
    if (!updateMode) {
      checkUsernameDebounced.current(e.target.value);
    }

    userProfileForm.clearErrors('handle');
    userProfileForm.setValue('handle', e.target.value, { shouldValidate: true });
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    const { handle, firstName, lastName } = formData;
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v1/auth/update-profile', {
        username: handle,
        first_name: firstName,
        last_name: lastName
      })
      .catch(() => {});

    if (!response || !response.data) {
      setLoading(false);

      return;
    }

    const { success, error } = get(response, 'data', {});
    if (!success && error) {
      error.fieldErrors.forEach(({ field, message }) =>
        userProfileForm.setError(field === 'username' ? 'handle' : field, { type: 'checkUser', message })
      );

      return;
    }

    onSubmit(formData);
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/user/profile?id=${id}`)
      .catch(() => {});

    const { success, data, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    userProfileForm.reset({
      ...get(pick(data, ['profile.firstName', 'profile.lastName']), 'profile', {}),
      handle: data.userName
    });

    setLoading(false);
  };

  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    }
  }, []);

  return (
    <div className="user-profile">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container maxWidth="sm">
        <Typography color="primary" variant="h1">
          Think You Can Handle This?
        </Typography>
        <Typography>Pick your @handle and become an instant fan, you can always change this later</Typography>

        <Box component="form" onSubmit={userProfileForm.handleSubmit(handleSubmit)} autoComplete="off">
          <Controller
            name="handle"
            control={userProfileForm.control}
            rules={{
              required: 'Handle is required',
              validate: {
                checkUser: () => {
                  const { handle } = userProfileForm.formState.errors;

                  return !(handle && handle.type === 'checkUser') || handle.message?.replace('Username', 'Handle');
                }
              }
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  label="@Handle"
                  variant="standard"
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      name: field.name,
                      mask: '@name',
                      valueMasked: false,
                      blocks: {
                        name: {
                          mask: /^[0-9a-zA-Z_]{1,25}$/
                        }
                      }
                    }
                  }}
                  helperText={
                    fieldState.error && (
                      <Typography variant="p" color="error">
                        {fieldState.error.message}
                      </Typography>
                    )
                  }
                />
              </>
            )}
          />

          <Typography color="primary" variant="h4">
            Can I Get Your Autograph?
          </Typography>
          <Typography>Tell us your name so we know it&apos;s you</Typography>

          <div>
            <Controller
              name="firstName"
              control={userProfileForm.control}
              rules={{ required: 'First Name is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="firstName"
                  label="First name"
                  variant="standard"
                  helperText={
                    fieldState.error && (
                      <Typography variant="p" color="error">
                        {fieldState.error.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="lastName"
              control={userProfileForm.control}
              rules={{ required: 'Last Name is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Last name"
                  variant="standard"
                  helperText={
                    fieldState.error && (
                      <Typography variant="p" color="error">
                        {fieldState.error.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-between">
            {updateMode && (
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                disabled={loading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            <Button type="submit" variant="outlined" size="large" color="primary" disabled={loading}>
              Save
            </Button>
          </div>
        </Box>
      </Container>
    </div>
  );
};

UserProfile.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false
};

UserProfile.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool
};

export default UserProfile;
