/* eslint-disable no-lonely-if */
// Packages
import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { Typography } from '@mui/material';

// Relatives
import VerifySms from './steps/VerifySms';
import LoginPhone from './steps/LoginPhone';
import VenuesCreate from '../Venues/VenuesCreate';
import UserContext from '../../contexts/UserContext/UserContext';
import UserProfile from './steps/UserProfile';
// import Organisation from './steps/Organisation';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';

const Login = () => {
  // const { isRegister } = props;
  const { setUser, getUser, setUserParams, setUserStatus, logout, refreshNGOs } = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const [mobilePhone, setMobilePhone] = useState('');
  const [phase, setPhase] = useState(() => {
    const user = getUser();
    if (!user) {
      return 'phase1';
    }

    const { status } = user;
    if (status === 'inactive') {
      return 'phase3';
    }

    return 'phase4';
  });
  const [confirmationToken, setConfirmationToken] = useState('');
  const navigate = useNavigate();

  const loginPhase1 = async data => {
    if (!data) {
      return;
    }

    const { mobilePhone, confirmationToken } = data;
    setMobilePhone(mobilePhone);
    setConfirmationToken(confirmationToken);
    setPhase('phase2');
  };

  const loginPhase2 = async data => {
    if (!data) {
      return;
    }

    const { id, token, userName, profile } = data;
    const sessionUserData = { id, token, status: 'active', params: { userName, profile } };

    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venues?owned_by=${id}`)
      .catch(() => {});

    const broadcasters = get(response, 'data.output.data', []);

    /* if(await response){
      console.log(get(response, 'data.output.data', []), broadcasters.length > 0, broadcasters.length);
      return;
    } */

    if (await response) {
      if (data.status === 'inactive') {
        sessionUserData.status = 'inactive';
        setUser(sessionUserData);
        setPhase('phase3');
        navigate('/register');
      } else {
        if (broadcasters.length > 0) {
          setUser(sessionUserData);
          setPhase('phase4');
        } else {
          sessionUserData.status = 'new-venues';
          setUser(sessionUserData);
          setPhase('phase4');
        }
      }
    }

    /* const { id, token, userName, profile } = data;
    if (data.status === 'inactive') {
      setUser({ id, token, status: 'inactive', params: { userName, profile } });
      setPhase('phase3');
      navigate('/register');
    } else if (isRegister) {
      setUser({ id, token, status: 'new-ngo', params: { userName, profile } });
      setPhase('phase4');
    } else {
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/v2/non-profit-ngos?sort=id&owned_by=${id}`)
        .catch(() => {});

      const ngos = get(response, 'data.output.data', []);
      if (ngos.length > 0) {
        setUser({ id, token, status: 'active', params: { userName, profile } });
        navigate('/');
      } else {
        setUser({ id, token, status: 'new-ngo', params: { userName, profile } });
        setPhase('phase4');
      }
    } */
  };

  const loginPhase3 = data => {
    if (!data) {
      return;
    }

    setUserParams({ userName: data.handle, stripeCustomerId: data });
    setUserStatus('new-venues');
    setPhase('phase4');
  };

  const loginPhase4 = data => {
    if (!data) {
      return;
    }

    // for worflow normal
    /*
        setUserStatus('active');
        navigate('/');
    */

    // for worflow temporary
    refreshNGOs();
    setUserStatus('active');
    navigate('/');
  };

  const reset = () => {
    setPhase('phase1');
    setConfirmationToken('');
    setMobilePhone('');
  };

  const loginCancel = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="Login text-center" key="login">
      <img src="./images/back.jpg" alt="register background" className="background" />
      {phase === 'phase1' && <LoginPhone onSubmit={loginPhase1} />}
      {phase === 'phase2' && (
        <VerifySms
          mobilePhone={mobilePhone}
          onSubmit={loginPhase2}
          onReset={reset}
          confirmationToken={confirmationToken}
        />
      )}
      {phase === 'phase3' && <UserProfile onSubmit={loginPhase3} />}
      {phase === 'phase4' && <VenuesCreate onSubmit={loginPhase4} />}
      {(phase === 'phase3' || phase === 'phase4') && (
        <div className="d-flex mt-4">
          <Typography type="button" component="p" color="primary" className="m-2" onClick={loginCancel}>
            <span>Log out</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

/* Login.defaultProps = {
  isRegister: false
};

Login.propTypes = {
  isRegister: PropTypes.bool
}; */

export default Login;
