// Packages
import React, { useState, useId } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import get from 'lodash/get';

// Relatives
import ImageModalCrop from './ImageModalCrop';

// Style
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropInput = props => {
  const { onCancel, onUpload, aspect, name } = props;
  const [value, setValue] = useState('');
  const [src, setSrc] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect });
  const [openCrop, setOpenCrop] = useState(false);
  const id = useId();

  const onSelectFile = e => {
    setValue(e.target.value);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result);
        setOpenCrop(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setOriginalFile(e.target.files[0]);
    }
  };

  const resetState = () => {
    setValue('');
    setOpenCrop(false);
    setSrc(null);
    setCrop({ unit: '%', width: 30, aspect });
    setOriginalFile(null);
  };

  const handleClickCancel = () => {
    setOpenCrop(false);
    resetState();
    onCancel();
  };

  const handleClickSubmit = async croppedImageUrl => {
    const blob = await fetch(croppedImageUrl).then(r => r.blob());
    onUpload(name, {
      originalSrc: src,
      originalFile,
      croppedImageUrl,
      file: new File([blob], originalFile.name, { ...pick(originalFile, ['type']) })
    });
    resetState(true);
  };

  return (
    <div className="image-crop-input">
      <div className="image-crop-input__input">
        <input id={id} type="file" accept="image/*" value={value} onChange={onSelectFile} />
        <label className="btn" type="button" htmlFor={id} title="Choose File">
          <i className="fas fa-pen" />
        </label>
      </div>
      <ImageModalCrop
        className="image-input__modal"
        src={src}
        fileName={get(originalFile, 'fileName', '')}
        openModal={openCrop}
        onClose={handleClickCancel}
        onSubmit={handleClickSubmit}
        crop={crop}
        setCrop={setCrop}
      />
    </div>
  );
};

ImageCropInput.defaultProps = {
  name: '',
  aspect: 16 / 9,
  onUpload: noop,
  onCancel: noop
};

ImageCropInput.propTypes = {
  name: PropTypes.string,
  aspect: PropTypes.number,
  onUpload: PropTypes.func,
  onCancel: PropTypes.func
};

export default ImageCropInput;
