import React, { forwardRef, useEffect, useState /* , useRef */ } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@mui/material';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import noop from 'lodash/noop';
// import { useNavigate } from 'react-router-dom';

// Relatives
import CommonModalUpload from '../../../components/common/modals/CommonModalUpload';
import logoDefault from '../../../styles/images/logo.png';
import bannerDefault from '../../../styles/images/campaignDefault.png';

const VenuesMainBannerType1 = forwardRef((props, ref) => {
  // const navigate = useNavigate();
  /* const bannerUpload = useRef();
    const logoUpload = useRef(); */

  const [tagslist, setTaglist] = useState([]);
  const [image, setImage] = useState();
  const [logo, setLogo] = useState();
  const [editable, setEditable] = useState('v-hidden');
  const [open, setOpen] = useState(false);
  const [settingsModal, setSettingsModal] = useState({});
  const { title, headline, subtitle, tags, imageUrl, logoUrl, editor, reload } = props;

  /* const handleClickUpdateUserProfile = () => {
      navigate(`/register/venues/update`);
    }; */

  const tagsListJsx = tagslist.map(tag => <span className="VenuesMainBannerType1__wrapper--tag">{tag}</span>);

  /* const handleChoosebanner = () => {
        const { current } = bannerUpload;
        (current || { click: true }).click();
    }; */

  /* const handleChooselogo = () => {
        const { current } = logoUpload;
        (current || { click: true }).click();
    }; */

  const handleOpenModal = settings => {
    setSettingsModal(settings);
    setOpen(true);
  };

  useEffect(() => {
    setTaglist(tags);
    setImage(imageUrl);
    setLogo(logoUrl);

    if (editor) {
      setEditable('show-btn');
    }
  });

  return (
    <div ref={ref} className="VenuesMainBannerType1">
      <div className="VenuesMainBannerType1__image--background">
        <img
          className="VenuesMainBannerType1__image--background-Tag"
          src={`${image}`}
          onError={event => {
            event.target.src = bannerDefault;
          }}
          alt="background-tag"
        />
        <Button
          onClick={() => {
            handleOpenModal({
              title: 'Upload Your Image Banner',
              idName: 'background_media_id',
              aspect: 21 / 9
            });
          }}
          className={`VenuesMainBannerType1__image--background-floating-btn ${editable}`}
        >
          <ModeEditIcon className="btn__mode-edit" />
        </Button>
      </div>
      <div className="VenuesMainBannerType1__wrapper">
        <div className="VenuesMainBannerType1__wrapper--logo">
          <img
            className="VenuesMainBannerType1__wrapper--logo-image"
            src={`${logo}`}
            onError={event => {
              event.target.src = logoDefault;
            }}
            alt="logo"
          />
          <Button
            onClick={() => {
              handleOpenModal({
                title: 'Upload Your Image Avatar',
                idName: 'media_id',
                aspect: 4 / 3
              });
            }}
            className={`VenuesMainBannerType1__wrapper--logo-floating-btn ${editable}`}
          >
            <ModeEditIcon className="btn__mode-edit" />
          </Button>
          <div className="VenuesMainBannerType1__wrapper--container">
            <h1 className="VenuesMainBannerType1__wrapper--title">{title}</h1>
            <span className="VenuesMainBannerType1__wrapper--subtitle">{subtitle}</span>
            <p className="VenuesMainBannerType1__wrapper--subtitle">{headline}</p>
          </div>
        </div>
        <div className="VenuesMainBannerType1__wrapper--tags">{tagsListJsx}</div>
      </div>
      <CommonModalUpload
        settings={settingsModal}
        openState={open}
        onUploaded={() => {
          reload();
        }}
        onClose={() => setOpen(false)}
      />
    </div>
  );
});

VenuesMainBannerType1.defaultProps = {
  editor: true,
  reload: noop,
  title: 'Loading...',
  headline: 'Headline',
  subtitle: 'Nightclub',
  imageUrl: '/images/party.png',
  logoUrl: '/images/logos/neon.png',
  tags: ['CLUB', 'FOOD', 'DRINK', 'MUSIC']
};

VenuesMainBannerType1.propTypes = {
  editor: PropTypes.bool,
  reload: PropTypes.func,
  title: PropTypes.string,
  headline: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  tags: PropTypes.array
};

export default VenuesMainBannerType1;
