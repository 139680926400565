import { CircularProgress } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import Moment from 'react-moment';

import Layout from '../../../../layouts/Layout';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext';
import http from '../../../../services/api/http';

const SpecialsHighlights = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const [venuesData, setVeneusData] = useState([]);

  const venuesMenuData = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venue-has-menu?menu_type=4&venue_id=${get(userNGOSelected, 'id')}`)
      .catch(() => {});

    const { output, success } = get(response, 'data');

    if (!success) {
      navigate('/');
      return;
    }

    setVeneusData(output.data);

    setTimeout(function () {
      setLoading(false);
    }, 500);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();

    if (window.confirm('Are you sure you want to delete it?')) {
      setLoading(true);
      await http(apiHost, token.accessToken, token.tokenType)
        .delete(`/api/web/v2/venue-has-menu/${id}`)
        .catch(() => {});
      venuesMenuData();
    }
  };

  useEffect(() => {
    if (userNGOSelected) {
      venuesMenuData();
    }
  }, [userNGOSelected]);

  return (
    <Layout
      key="venuesProfile"
      title="Venues Profile"
      breadcrumb={[<Link to="/">Home</Link>, <Link to="/venues/highlights">Highlights</Link>, 'Specials']}
    >
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <div className="dashboard-index align-items-start">
        <div className="col-12 text-right">
          <Link to={{ pathname: '/venues/highlights/specials/form' }} className="btn btn-primary px-5">
            Create New
          </Link>
        </div>
        <div className="row">
          {venuesData.map((item, index) => (
            <div className="col-md-4" key={index}>
              <div className="highlights-category_item">
                {item._misc.media.type === 'application/pdf' ? (
                  <>
                    <a className="pdf" href={item._misc.media.url} target="_blank" rel="noreferrer">
                      <img src="/images/icons/pdf_ico.svg" alt="pdf icon" />
                      Click here to view
                    </a>
                  </>
                ) : (
                  <>
                    <img src={item._misc.media.original_image} alt="menu" width="100%" />
                  </>
                )}
                <h3>{item.title}</h3>
                {item.url ? (
                  <>
                    <p>
                      <i>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          {item.url}
                        </a>
                      </i>
                    </p>
                  </>
                ) : (
                  <>
                    <br />
                  </>
                )}

                <p>
                  <span>Start Date :</span>{' '}
                  <Moment style={{ whiteSpace: 'pre' }} format="MM/DD/YYYY">
                    {item.start_date * 1000}
                  </Moment>
                </p>
                <p>
                  <span>End Date :</span>
                  <Moment style={{ whiteSpace: 'pre' }} format="MM/DD/YYYY">
                    {item.end_date * 1000}
                  </Moment>
                </p>
              </div>
              <div className="highlights-category__actions">
                <Link to={`/venues/highlights/specials/form/${item.id}`}>Edit</Link>
                <button type="button" onClick={e => handleDelete(e, item.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default SpecialsHighlights;
