// Packages
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Payment } from '@picklejarapp/stripe-payments';
import UserContext from '../../../contexts/UserContext/UserContext';
import AppContext from '../../../contexts/AppContext/AppContext';
import http from '../../../services/api/http';

const ArtistSearch = () => {
  const { id, token, getUser } = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [state, setState] = useState({
    amount: '',
    memo: '',
    isValidAmount: false,
    isValidMemo: false
  });

  const user = getUser();
  let ownerType = 'artist';
  let ownerId;
  const userDetails = {
    token: token.accessToken,
    tokenType: token.tokenType,
    id
  };

  if (selectedUser.artist) {
    ownerType = 'artist';
    ownerId = selectedUser.artist.id;
  }

  if (selectedUser.band) {
    ownerType = 'band';
    ownerId = selectedUser.band.id;
  }

  useEffect(() => {
    const isValidAmount = state.amount === '' || /^[0-9]+(\.[0-9]{1,2})?$/.test(state.amount);
    setState(prevState => ({ ...prevState, isValidAmount }));
  }, [state.amount]);

  useEffect(() => {
    const isValidMemo = state.memo.trim().length > 0;
    setState(prevState => ({ ...prevState, isValidMemo }));
  }, [state.memo]);

  const handleAmountChange = e => {
    const { value } = e.target;

    setState(prevState => ({ ...prevState, amount: value }));
    const isValidAmount = value === '' || /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
    setState(prevState => ({ ...prevState, isValidAmount }));
  };

  const handleMemoChange = e => {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, memo: value }));
    const isValidMemo = value.trim().length > 0;
    setState(prevState => ({ ...prevState, isValidMemo }));
  };

  const handleItemSearch = async e => {
    if (e.target.value.length > 2) {
      setIsLoading(true);
      setSearchTerm(e.target.value);
      const response = await http(apiHost, token.accessToken, token.tokenType).get(
        `/api/web/v1/search-handle/search?exclude_business=1&exclude_child_labels=1&exclude_users=1&exclude_labels=1&handle=${e.target.value}&stage_name=${e.target.value}`
      );

      const { success, data } = response.data;

      if (success) {
        console.log(data);
        setSearchList(data);
        setIsLoading(false);
      }

      if (!success) {
        setIsLoading(false);
      }

      return;
    }
  };

  const handleSelectItem = (e, item) => {
    console.log(item);
    setSelectedId(item.id);
    setSelectedUser(item);
    setSearchList([]);
  };

  const handlePaymentConfirm = () => {
    setShowConfirm(true);
  };

  const paymentDone = () => {
    setState({
      amount: '',
      memo: '',
      isValidAmount: false,
      isValidMemo: false
    });
    setShowConfirm(false);
  };

  useEffect(() => {}, [searchTerm]);

  return (
    <div className="search-listing">
      {!showConfirm ? (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2 className="heading">Pay Artist</h2>

            <div className="position-relative">
              <label htmlFor="handlename">Search Artist</label>
              <input
                type="text"
                name="handlename"
                placeholder="Type Artist name or @stagename"
                onKeyUp={e => handleItemSearch(e)}
                defaultValue={selectedUser.handle}
                value={selectedUser.handle}
              />
              <div className="text-center">{isLoading ? <i className="fa fa-spinner fa-spin fa-2x p-3" /> : null}</div>
              <div className="artist-list">
                <ul className="list-unstyled m-0">
                  {searchList.map((item, index) => (
                    <li
                      key={index}
                      className={item.id === selectedId ? 'active' : ''}
                      onClick={e => handleSelectItem(e, item)}
                    >
                      <div className="profile">
                        {item.avatar.originalImage ? (
                          <img src={item.avatar.originalImage} alt="profile" className="img-fluid" />
                        ) : (
                          <img
                            src="https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png"
                            alt="profile"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div>
                        <h3>{item.stageName}</h3>
                        <h4>{item.handle}</h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <label htmlFor="amount">Amount</label>
              <span className="addon">&nbsp; ($)</span>
              <input type="text" name="amount" placeholder="0.00" onChange={handleAmountChange} value={state.amount} />
              {!state.isValidAmount && state.amount !== '' && (
                <span style={{ color: 'red' }}>Please enter a valid amount</span>
              )}
            </div>
            <div>
              <label htmlFor="memo">Memo</label>
              <textarea
                rows={3}
                name="memo"
                placeholder="Enter your message here"
                value={state.memo}
                onChange={handleMemoChange}
              />
              {!state.isValidMemo && state.memo !== '' && <span style={{ color: 'red' }}>Please enter a memo</span>}
            </div>
            <button
              type="button"
              disabled={!state.isValidAmount || !state.isValidMemo}
              onClick={handlePaymentConfirm}
              className="border-0 btn btn-primary d-flex justify-content-center mx-auto py-2 rounded-0 w-50"
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2 className="heading">Please Verify Details</h2>
            <Link className="d-flex align-items-center justify-content-end" onClick={e => setShowConfirm(false, e)}>
              <i className="fa fa-edit">&nbsp;</i> Edit
            </Link>

            <div className="position-relative">
              <label htmlFor="handlename">Search Artist</label>
              <input type="text" name="handlename" value={selectedUser.handle} readOnly />
            </div>
            <div>
              <label htmlFor="amount">Amount</label>
              <span className="addon">&nbsp; ($)</span>
              <input type="text" name="amount" placeholder="0.00" value={`$${state.amount}`} readOnly />
            </div>
            <div>
              <label htmlFor="memo">Memo</label>
              <textarea rows={3} name="memo" placeholder="Enter your message here" readOnly value={state.memo} />
            </div>

            <Payment
              apiHost={apiHost}
              userDetails={userDetails}
              ownerId={ownerId}
              amount={state.amount}
              message={state.memo}
              ownerType={ownerType}
              onSuccessPayment={paymentDone}
              stripeCustomerId={user.params.profile.customerStripeId}
              stripePkKey={process.env.REACT_APP_STRIPE_PK_KEY}
              stripeSkKey={process.env.REACT_APP_STRIPE_SK_KEY}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ArtistSearch.defaultProps = {};

ArtistSearch.propTypes = {};

export default ArtistSearch;
