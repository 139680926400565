/* eslint-disable camelcase */
// Packages
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// Relatives
import Moment from 'react-moment';
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import ParticipantList from './components/ParticipantList';

const FundraiserDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [tipsCollected, setTipsCollected] = useState(0);
  const [loading, setLoading] = useState(true);
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}`)
      .catch(() => {});

    const responseData = get(response, 'data', {});
    if (!responseData.success) {
      setLoading(false);
      console.warn(responseData.error);

      return;
    }

    console.log(userNGOSelected, 'user');

    const eventOwners = get(responseData, 'output._related.event_admin_users', []);
    const eventType = get(responseData, 'output.event_type', '');
    const owner = eventOwners.find(
      ({ user_id }) =>  user_id === userNGOSelected.owned_by
    );

    console.log(owner, eventOwners, userNGOSelected.owned_by, 'owner')

    if (!owner || eventType !== 'association-fundraiser') {
      navigate('/fundraisers', { state: { alerts: ['Fundraiser not found'] } });

      return;
    }

    setEvent(responseData.output);
    const response2 = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}/stats`)
      .catch(() => {});

    setLoading(false);
    const response2Data = get(response2, 'data', {});
    if (!response2Data.success) {
      console.warn('error');

      return;
    }

    setTipsCollected(response2Data.output.tips_amount);
  };

  useEffect(() => {
    setLoading(true);
    loadModel();

    return () => {
      setLoading(false);
    };
  }, [id]);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }),
    []
  );

  const participants = useMemo(
    () =>
      get(event, '_related.event_performances', []).map(participant => {
        const {
          _related: { artist, band }
        } = participant;

        if (artist) {
          const { handle } = artist;
          const largeImage = get(artist, '_misc.avatar.large_image');

          return {
            avatarImage: largeImage || null,
            name: handle
          };
        }

        if (band) {
          const { handle } = band;
          const largeImage = get(band, '_misc.avatar.large_image');

          return {
            avatarImage: largeImage || null,
            name: handle
          };
        }

        return {
          avatarImage: null,
          name: null
        };
      }),
    [event]
  );

  if (loading || !event) {
    return null;
  }

  const { name, message, formatted_address: fullAddress, target_goal: targetGoal, city } = event;
  const dateTimeStart = get(event, '_related.event_dates.0.start_date');
  const dateTimeEnd = get(event, '_related.event_dates.0.end_date');
  const eventAdmins = get(event, '_related.event_admin_users');
  const avatarLarge = get(event, '_misc.avatar.large_image');
  const bannerLarge = get(event, '_misc.banner.large_image');

  let fromDate;
  let toDate;
  if (dateTimeStart && dateTimeEnd) {
    fromDate = new Date(dateTimeStart).getTime() * 1000;
    toDate = new Date(dateTimeEnd).getTime() * 1000;
  }

  return (
    <Layout title={`Fundraiser - ${name}`} breadcrumb={['Home', <Link to="/fundraisers">Fundraisers</Link>, name]}>
      <div className="fundraiser-view">
        <div className="view__container col-12 col-md-8 container--left">
          <div className="event__title">{name}</div>
          <div className="event__address">
            <i className="fas fa-map-marker-alt" />
            <div className="address__full-address">
              <div className="full-address__address">{fullAddress}</div>
              <div className="full-address__city">{city}</div>
            </div>
          </div>
          <div className="event__header">
            <div className="header__banner" style={{ backgroundImage: `url(${bannerLarge})` }} />
            <div className="header__banner-details">
              <div className="event__avatar" style={{ backgroundImage: `url(${avatarLarge})` }} />
              <div className="event__participants">
                <div className="participants__title">Supporters</div>
                <ParticipantList items={participants} eventId={id} />
              </div>
            </div>
          </div>
          <div className="event__body">
            <div className="event__title justify-content-center">DESCRIPTION</div>
            <p className="text-align-justify">{message}</p>
            <div className="event__sub-title">Organizer</div>
            <div className="event__admins">
              {eventAdmins.map((admin, i) => {
                const adminAvatar = get(admin, 'avatar_medium');

                return (
                  <div key={i} className="admins__admin">
                    <div
                      className="admin__avatar"
                      style={{ backgroundImage: adminAvatar ? `url(${adminAvatar})` : null }}
                    />
                    <div className="admin__details">
                      <div className="admin__username">{get(admin, 'handle', '')}</div>
                      <div className="admin__location">{`${get(admin, 'hometown') || ''} ${get(
                        admin,
                        'country',
                        ''
                      )}`}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="view__container col-12 col-md-4 container--right">
          <div className="view__container bg--black">
            {targetGoal !== undefined && (
              <div className="event__goal">
                <div className="goal__text">
                  <div className="text-primary">{formatter.format(tipsCollected)}</div> raised from the goal of{' '}
                  {formatter.format(targetGoal)}
                </div>
                <div className="goal__progress">
                  <div
                    className="progress__bar"
                    style={{
                      width: `${(tipsCollected / targetGoal) * 100 > 100 ? 100 : (tipsCollected / targetGoal) * 100}%`
                    }}
                  />
                </div>
                <div className="goal__total-donations">{formatter.format(tipsCollected)}</div>
              </div>
            )}
            {targetGoal === undefined && <div className="event__goal">Target Goal not defined</div>}
            <div className="event__actions">
              {/* <button className="btn btn-primary w-100" type="button">
                SHARE <i className="fas fa-share-alt" />
              </button> */}
              <Link to="update" className="btn btn-primary w-100">
                EDIT
              </Link>
              <Link to="funds" className="btn btn-primary w-100">
                FUNDRAISER PROGRESS
              </Link>
            </div>
          </div>
          {dateTimeStart && dateTimeEnd && (
            <div className="view__container">
              <div className="event__time">
                <div className="time__item">
                  <div className="time__type">FROM</div>
                  <div className="time__date">
                    <Moment style={{ whiteSpace: 'pre' }} format={'MMM[\n]DD'}>
                      {fromDate}
                    </Moment>
                  </div>
                  <div className="time__time">
                    <Moment format="HH:mm">{fromDate}</Moment>EST
                  </div>
                </div>
                <div className="time__item">
                  <div className="time__type">TO</div>
                  <div className="time__date">
                    <Moment style={{ whiteSpace: 'pre' }} format={'MMM[\n]DD'}>
                      {toDate}
                    </Moment>
                  </div>
                  <div className="time__time">
                    <Moment format="HH:mm">{toDate}</Moment>
                    <span>EST</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default FundraiserDetails;
