// Packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router';

// Relatives
import Welcome from './pages/Welcome';
import Login from './pages/Login';
// import DashboardIndex from './pages/Dashboard/DashboardIndex';
/* import VenuesIndex from './pages/Venues/VenuesIndex';
import VenuesFeaturedArtists from './pages/Venues/VeneusFeaturedArtists';
import VeneusGallery from './pages/Venues/VenuesGallery';
import ScheduleIndex from './pages/Schedule/ScheduleIndex';
import AwardsIndex from './pages/Awards/AwardsIndex';
import MessengerIndex from './pages/Messenger/MessengerIndex';
import MessengerMemberList from './pages/Messenger/MessengerMemberList';
import MessengerPatronBadges from './pages/Messenger/MessengerPatronBadges';
import MessengerComms from './pages/Messenger/MessengerComms';
*/
// import ThankYouPage from './pages/ThankYouPage';
// import ModalDialog from './pages/Login/modals/ModalDialog';
import DashboardComponent from './templates/dashboard/DashboardComponent';
import EventsIndex from './pages/Events/EventsIndex';
import ArtistPayNow from './pages/ArtistPayNow/ArtistPayNowIndex';
import EventsCreate from './pages/Events/EventsCreate';
import EventsDetails from './pages/Events/EventsDetails';
// import EventsMoney from './pages/Events/EventsMoney';
import CampaignIndex from './pages/Campaigns/CampaignIndex';
import CampaignCreate from './pages/Campaigns/CampaignCreate';
import CampaignDetails from './pages/Campaigns/CampaignDetails';
import CampaignMoney from './pages/Campaigns/CampaignMoney';
import FundraiserIndex from './pages/Fundraiser/FundraiserIndex';
import FundraiserDetails from './pages/Fundraiser/FundraiserDetails';
import FundraiserMoney from './pages/Fundraiser/FundraiserMoney';
import FundraiserCreate from './pages/Fundraiser/FundraiserCreate';
import VenuesProfile from './pages/Venues/VenuesProfile';
import VenuesCreate from './pages/Venues/VenuesCreate';
import ProfileUpdate from './pages/User/ProfileUpdate';
import VenueHighlights from './pages/Venues/VenueHighlights';
import MenuHighlights from './pages/Venues/highlights/menu/MenuHighlights';
import MenuForm from './pages/Venues/highlights/menu/MenuForm';
import DrinksHighlights from './pages/Venues/highlights/drinks/DrinksHighlights';
import DrinksForm from './pages/Venues/highlights/drinks/DrinksForm';
import CalanderHighlights from './pages/Venues/highlights/calendar/CalendarHighlights';
import CalendarForm from './pages/Venues/highlights/calendar/CalendarForm';
import SpecialsHighlights from './pages/Venues/highlights/specials/SpecialsHighlights';
import SpecialsForm from './pages/Venues/highlights/specials/SpecialsForm';

import { theme } from './const/Theme';
import AppContext from './contexts/AppContext';
import UserProvider from './contexts/UserContext/UserProvider';
import UserContext from './contexts/UserContext/UserContext';
import { getApiHost, getHost, getEnvironment } from './config/settings';

// Styles
import './styles/theme.scss';
import './App.css';

const App = props => {
  const { sessionKey } = props;

  const environment = useMemo(() => getEnvironment(), []);

  const apiHost = getApiHost(environment);
  const host = getHost(environment);

  const appContextValue = useMemo(() => ({ host, apiHost }), [host, apiHost]);

  // const tracklogin = localStorage.getItem('tracklogin') || 'notlogged';

  return (
    <AppContext.Provider value={appContextValue}>
      <UserProvider sessionKey={sessionKey}>
        <UserContext.Consumer>
          {context => {
            const { isLogged, status } = context;

            return (
              <ThemeProvider theme={theme}>
                <div className="App">
                  <Routes>
                    isLogged && <Route exact path="/dashboard" element={<DashboardComponent />} />
                    {/* isLogged && <Route exact path="/gallery" element={<VeneusGallery />} /> */}
                    {/* isLogged && <Route exact path="/featured" element={<VenuesFeaturedArtists />} /> */}
                    {isLogged && <Route exact path="/venues/profile" element={<VenuesProfile />} />}
                    {/* isLogged && <Route exact path="/venues" element={<VenuesIndex />} /> */}
                    {/* isLogged && <Route exact path="/schedule" element={<ScheduleIndex />} /> */}
                    {/* isLogged && <Route exact path="/awards" element={<AwardsIndex />} /> */}
                    {/* isLogged && <Route exact path="/messenger/direct" element={<MessengerIndex />} /> */}
                    {/* isLogged && <Route exact path="/messenger/members" element={<MessengerMemberList />} /> */}
                    {/* isLogged && <Route exact path="/messenger/patronbadges"
                    element={<MessengerPatronBadges />} /> */}
                    {/* isLogged && <Route exact path="/messenger/comms" element={<MessengerComms />} /> */}
                    {isLogged && <Route exact path="/reload" element={<Navigate to="/venues/profile" />} />}
                    {isLogged && (
                      <Route
                        exact
                        path="/venues/profile/update/operationhours"
                        element={<VenuesCreate stepParam={2} updateMode />}
                      />
                    )}
                    {isLogged && <Route exact path="/venues/profile/update" element={<VenuesCreate updateMode />} />}
                    {isLogged && (
                      <Route exact path="/register/venues/create" element={<VenuesCreate multiCreateMode />} />
                    )}
                    {isLogged && <Route exact path="/fundraisers/:id/funds" element={<FundraiserMoney />} />}
                    {isLogged && (
                      <Route exact path="/fundraisers/:id/update" element={<FundraiserCreate updateMode />} />
                    )}
                    {isLogged && <Route exact path="/fundraisers/create" element={<FundraiserCreate />} />}
                    {isLogged && <Route exact path="/fundraisers/:id" element={<FundraiserDetails />} />}
                    {isLogged && <Route exact path="/fundraisers" element={<FundraiserIndex />} />}
                    {isLogged && <Route exact path="/campaigns/:id/funds" element={<CampaignMoney />} />}
                    {isLogged && <Route exact path="/campaigns/:id/update" element={<CampaignCreate updateMode />} />}
                    {isLogged && <Route exact path="/campaigns/create" element={<CampaignCreate />} />}
                    {isLogged && <Route exact path="/campaigns/:id" element={<CampaignDetails />} />}
                    {isLogged && <Route exact path="/campaigns" element={<CampaignIndex />} />}
                    {isLogged && <Route exact path="/events" element={<EventsIndex />} />}
                    {isLogged && <Route exact path="/artist-pay-now" element={<ArtistPayNow />} />}
                    {/* isLogged && <Route exact path="/events/:id/funds" element={<EventsMoney />} /> */}
                    {isLogged && <Route exact path="/events/:id/update" element={<EventsCreate updateMode />} />}
                    {isLogged && <Route exact path="/events/create" element={<EventsCreate />} />}
                    {isLogged && <Route exact path="/events/:id" element={<EventsDetails />} />}
                    {isLogged && <Route exact path="/user/profile/update" element={<ProfileUpdate updateMode />} />}
                    {isLogged && <Route exact path="/venues/highlights" element={<VenueHighlights />} />}
                    {isLogged && <Route exact path="/venues/highlights/menu" element={<MenuHighlights />} />}
                    {isLogged && <Route exact path="/venues/highlights/menu/form" element={<MenuForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/menu/form/:id" element={<MenuForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/drinks" element={<DrinksHighlights />} />}
                    {isLogged && <Route exact path="/venues/highlights/drinks/form" element={<DrinksForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/drinks/form/:id" element={<DrinksForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/calendar" element={<CalanderHighlights />} />}
                    {isLogged && <Route exact path="/venues/highlights/calendar/form" element={<CalendarForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/calendar/form/:id" element={<CalendarForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/specials" element={<SpecialsHighlights />} />}
                    {isLogged && <Route exact path="/venues/highlights/specials/form" element={<SpecialsForm />} />}
                    {isLogged && <Route exact path="/venues/highlights/specials/form/:id" element={<SpecialsForm />} />}
                    {!isLogged && <Route exact path="/register" element={<Login isRegister />} />}
                    {!isLogged && <Route exact path="/login" element={<Login />} />}
                    {status !== 'new-ngo' /* && tracklogin === 'notlogged' */ && (
                      <Route
                        exact
                        path="/"
                        element={!isLogged ? <Welcome /> : <Navigate replace to="/venues/profile" />}
                      />
                    )}
                    {/* status !== 'new-ngo' && tracklogin === 'login' && (
                      <Route exact path="/" element={!isLogged ? <Welcome /> :
                        <Navigate replace to="/venues/profile" />} />
                    ) */}
                    {/* status !== 'new-ngo' && tracklogin === 'register' && (
                      <Route exact path="/" element={!isLogged ? <Welcome /> : <ModalDialog />} />
                    ) */}
                    {status === 'new-ngo' && <Route path="*" element={<Navigate replace to="/register" />} />}
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </div>
              </ThemeProvider>
            );
          }}
        </UserContext.Consumer>
      </UserProvider>
    </AppContext.Provider>
  );
};

App.defaultProps = {
  sessionKey: 'kaDa6ptP7Bk2xP3'
  // host: 'http://pj-jose-ai.ngrok.io'
};

App.propTypes = {
  sessionKey: PropTypes.string
};

export default App;
