// Packages
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';

// Relatives
import UserContext from '../../../contexts/UserContext';
import SelectNGO from '../../../components/SelectNGO/SelectNGO';

const FundraiserStep1 = props => {
  const { updateMode } = props;
  const { control, errors, register, setValue, getValues } = useFormContext();

  const { userNGOSelected } = useContext(UserContext);
  const [backSelected, setBackSelected] = useState(getValues('association'));

  useEffect(() => {
    setValue('association', getValues('association'));
    setValue('ngoId', getValues('ngoId'));
    // setValue('artist_ids[0]', getValues('artist_ids[0]'));
    setValue('artist_ids[0]', getValues('artist_ids[0]'));
    setValue('additional_event_admins', getValues('additional_event_admins'));
    setBackSelected(getValues('association'));
  }, [backSelected]);

  return (
    <>
      {!updateMode && <Grid item xs={12}>
      <SelectNGO setValue={backSelected} data={data => {
                setValue('association', data.newNgoName);
                setValue('ngoId', data.ngoId);
                // setValue('artist_ids[0]', userNGOSelected.virtual_artist_id);
                setValue('artist_ids[0]', data.virtualArtistId);
                setValue('additional_event_admins[0]', data.virtualArtistUserId);
                setValue('additional_event_admins[1]', userNGOSelected.owned_by);
              }} />
              <div className='visually-hidden'>
                <TextField {...register("association", { required: 'Association is required' })} type="hidden" />
                <TextField {...register("ngoId")} type='hidden' />
                <TextField {...register("artist_ids[0]")} type='hidden' />
                <TextField {...register("additional_event_admins[0]")} type='hidden' />
                <TextField {...register("additional_event_admins[1]")} type='hidden' />
              </div>
              <ErrorMessage
                errors={errors}
                name="association"
                render={({ message }) =>  (
                  <Typography style={{ fontSize: "0.75rem !important" }} className='w-100 text-left mt-2 mb-2 f-special-075rem' variant="p" color="error">
                    {message}
                  </Typography>
                )}
              />
      </Grid>}
      {updateMode && <Grid item xs={12}>
          <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root disabled">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Organization Name
                </label>
              </div>
              <TextField inputProps={{
                readOnly: true
              }} {...register("association")} variant="standard" />
          </div>
      </Grid>}
      <Grid className='mt-4' item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Fundraiser Name"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                Message
              </label>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

FundraiserStep1.defaultProps = {
  updateMode: false
};

FundraiserStep1.propTypes = {
  updateMode: PropTypes.bool
};
export default FundraiserStep1;
