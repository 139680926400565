/* eslint-disable camelcase */
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';

// Relatives
import AddressLookup from '../../../components/AddressLookup';

const EventsStep2 = props => {
  const { updateMode } = props;
  const { control, setValue, getValues } = useFormContext();

  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');

      return;
    }

    const {
      formatted_address,
      streetNumber,
      streetName,
      postalCode,
      stateName,
      locality,
      occupancyIdentifier,
      countryCode
    } = address;

    setValue('country', countryCode);
    setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    setValue('street_address_2', occupancyIdentifier);
    setValue('city', locality);
    setValue('state', stateName);
    setValue('zip', postalCode);

    fieldOnChange(formatted_address);
  };

  const handleIsManualMode = isManual => {
    if (isManual === true) {
      setValue('address_manual_mode', true);
    }

    if (!isManual) {
      setValue('address_manual_mode', false);
    }
  };

  return (
    <>
      <Grid className={updateMode ? 'editor' : 'creator'} item xs={12}>
        <Controller
          name="formatted_address"
          control={control}
          render={({ field, fieldState }) => {
            const { value, onChange } = field;

            return (
              <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Address
                </label>
                <AddressLookup
                  onChangeMode={handleIsManualMode}
                  onChange={handleChange(onChange)}
                  value={value}
                  placeholder="Write your address..."
                >
                  <Grid item xs={12}>
                    <Controller
                      name="street_address"
                      control={control}
                      rules={{ required: 'Address is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address"
                          variant="standard"
                          placeholder="Street Address or P.O. Box"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="street_address_2"
                      control={control}
                      // rules={{ required: 'Address 2 is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address 2"
                          variant="standard"
                          placeholder="Apt, Suite, Unit Building (optional)"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex">
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: 'City is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="City"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: 'State is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="State"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: 'Zip is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Zip"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: 'Country is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Country"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </AddressLookup>
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            );
          }}
        />
      </Grid>
      <Typography color="primary" variant="h1" className="text-center mx-auto">
        Date & Time
      </Typography>
      <Grid item xs={12} className="d-flex">
        <Controller
          name="date_from"
          control={control}
          rules={{
            required: 'From is required',
            validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                {...field}
                label="From"
                minDate={new Date()}
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_to"
          control={control}
          rules={{
            required: 'To is required',
            validate: {
              dateInvalid: value => {
                const momentDate = moment(value, 'MM/DD/YYYY', true);
                const diff = momentDate.diff(moment(new Date().setUTCHours(0, 0, 0, 0)));
                const diffFrom = momentDate.diff(getValues('date_from'));

                return diff < 0 || diffFrom < 0 || !momentDate.isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                {...field}
                label="To"
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                minDate={new Date()}
                invalidDateMessage="End date is required"
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </>
  );
};

EventsStep2.defaultProps = {
  updateMode: false
};

EventsStep2.propTypes = {
  updateMode: PropTypes.bool
};

export default EventsStep2;
