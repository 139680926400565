/* eslint-disable operator-linebreak */

// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { CircularProgress } from '@mui/material/';
import { Alert } from 'reactstrap';

// Relatives
import Layout from '../../layouts/Layout';
import VenuesFilter2 from './components/VenuesFilter2';
import CardDetailsVenuesType1 from './components/CardDetailsVenuesType1 ';
import VenuesMainBannerType1 from './components/VenuesMainBannerType1';
import VeneusProfileChart from './components/VenuesProfileChart';
import MapView from './components/MapView';
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';

const VenuesProfile = () => {
  const { token, logout, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();
  const { apiHost } = useContext(AppContext);
  const [description, setDescription] = useState();
  const [venuesData, setVeneusData] = useState();
  const [listSchedules, setListSchedules] = useState();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  console.log(venuesData);
  const venuesProfileData = async () => {
    // new
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venues/${get(userNGOSelected, 'id')}`)
      .catch(() => {
        logout();
        window.location.reload();
      });

    const { output, success } = get(response, 'data');

    if (!success) {
      navigate('/');
      return;
    }

    setVeneusData(output);
    generateListSchedules(response);
    setDescription(get(output, 'bio'));

    setTimeout(function () {
      setLoading(false);
    }, 500);
  };

  const dataChartBuild = (apiData, size) => {
    const newArray = [];

    for (let index = 1; index < size + 1; index++) {
      newArray.push(apiData[index].value);
    }

    return newArray;
  };

  const VenuesProfileStatsData = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/venues/${get(userNGOSelected, 'id')}/stats`)
      .catch(() => {
        logout();
        window.location.reload();
      });

    const { output, success } = get(response, 'data');

    if (!success) {
      navigate('/');
      return;
    }

    if (success) {
      // re-organization for the chart
      const preYearData = get(output, 'current_year_sent_tips_report');
      const preMonthData = get(output, 'current_month_sent_tips_report');
      const preWeekData = get(output, 'current_week_sent_tips_report');

      // build a new array structure
      const yearData = dataChartBuild(preYearData, 12);
      const monthData = dataChartBuild(preMonthData, 5);
      const weekData = dataChartBuild(preWeekData, 7);

      // memorizing data
      const ChartDataPost = {
        dataYearly: yearData,
        dataMonthly: monthData,
        dataWeekly: weekData,
        labelsMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labelsWeekly: ['Week #1', 'Week #2', 'Week #3', 'Week #4', 'Week #5'],
        labelsYearly: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      };

      setChartData(ChartDataPost);
    }
  };

  const generateListSchedules = data => {
    const list = [];

    const venuesOperationHours = get(data, 'data.output._misc.operation_hours');

    [...venuesOperationHours].map(hour => {
      if (hour.is_active === true) {
        list.push({
          title: hour.day_name,
          details: [
            {
              dateTo: hour.hour_open,
              dateEnd: hour.hour_close
            }
          ]
        });
      }

      return;
    });

    setListSchedules({
      datesList: list
    });
  };

  useEffect(() => {
    if (userNGOSelected) {
      venuesProfileData();
      VenuesProfileStatsData();
    }
  }, [userNGOSelected]);

  return (
    <Layout key="venuesProfile" title="Venues Profile" breadcrumb={[<Link to="/">Home</Link>, 'Profile']}>
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <div className="dashboard-index d-flex p-0">
        <div className="col-12 col-md-3">
          <VenuesFilter2 />
        </div>
        <div className="col-12 col-md-9 px-4 mt-5 mt-md-0">
          <div className="col-12">
            {venuesData && (
              <VenuesMainBannerType1
                id={get(venuesData, 'id')}
                editor
                title={get(venuesData, 'name')}
                headline={get(venuesData, 'headline')}
                subtitle={get(venuesData, '_related.venue_types.0.name')}
                imageUrl={
                  get(venuesData, '_misc.banner.medium_image')
                    ? `${get(venuesData, '_misc.banner.medium_image')}`
                    : 'https://dummyimage.com/876x250/000/fff&text=Loading...'
                }
                logoUrl={
                  get(venuesData, '_misc.avatar.small_image')
                    ? `${get(venuesData, '_misc.avatar.small_image')}`
                    : 'https://dummyimage.com/96x96/000/fff&text=Loading...'
                }
                tags={[]}
                reload={() => {
                  venuesProfileData();
                }}
              />
            )}
          </div>
          <div className="col-12 Veneus_description">
            <Link className="float-end btn-edit__venue" to="/venues/profile/update">
              Edit Venue Profile
            </Link>
            <h4>Description</h4>
            {!!description && <p>{description}</p>}
            {!description && <p>&nbsp;</p>}
          </div>
          <div className="col-12">
            {venuesData && (
              <CardDetailsVenuesType1
                id={get(venuesData, 'id')}
                editor
                locationAddress={get(venuesData, 'formatted_address')}
                geometry={{
                  lat: Number(get(venuesData, 'lat')),
                  lng: Number(get(venuesData, 'lon'))
                }}
                website={get(venuesData, 'website') !== '' ? get(venuesData, 'website') : 'N/A'}
                phone={get(venuesData, 'phone_number') !== '' ? get(venuesData, 'phone_number') : 'N/A'}
                email={get(venuesData, 'email') !== '' ? get(venuesData, 'email') : 'N/A'}
                hours={`${get(venuesData, 'hour_open')} - ${get(venuesData, 'hour_close')}`}
                listschedule={listSchedules}
              />
            )}
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="Venues_container-special p-4 px-0 px-md-4">
                  <h4>analytics</h4>
                  {venuesData && (
                    <VeneusProfileChart
                      dataMonthly={chartData.dataMonthly}
                      dataWeekly={chartData.dataWeekly}
                      dataYearly={chartData.dataYearly}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Venues_container-special p-4 px-0 px-md-4 text-center">
                  <h4>Map</h4>

                  {venuesData && (isNil(venuesData.lat) || isNil(venuesData.lon)) && (
                    <>
                      <Alert color="warning">
                        <Link
                          onMouseOver={event => {
                            event.target.classList.remove('text-dark');
                          }}
                          onMouseLeave={event => {
                            event.target.classList.add('text-dark');
                          }}
                          className="text-decoration-none text-dark"
                          to="/venues/profile/update"
                        >
                          No Geolocation available. Please update your address here
                        </Link>
                      </Alert>
                      <MapView
                        center={{
                          lat: 40.4406076,
                          lng: -3.7052551
                        }}
                        zoom={1}
                      />
                      <a href="http://maps.apple.com/maps?q=40.4406076, -3.7052551">Open in Maps</a>
                    </>
                  )}
                  {venuesData && !isNil(venuesData.lat) && !isNil(venuesData.lon) && (
                    <>
                      <MapView
                        center={{
                          lat: Number(get(venuesData, 'lat')),
                          lng: Number(get(venuesData, 'lon'))
                        }}
                        zoom={18}
                        marker
                      />
                      <a
                        href={`http://maps.apple.com/maps?q=${Number(get(venuesData, 'lat'))}, ${Number(
                          get(venuesData, 'lon')
                        )}`}
                      >
                        Open in Maps
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VenuesProfile;
