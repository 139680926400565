// Packages
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListItemAvatar, Avatar } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import get from 'lodash/get';

// Relatives
import ProfileMenu from '../Menu/ProfileMenu';
import AvatarImg from '../../../styles/images/avatarDefault.png';

const AvatarUser = props => {
  const {
    user: { userNGOs, setUserNGO, userNGOSelected, params }
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const parentRef = useRef(null);

  const handleMenuClose = () => setIsMenuOpen(false);

  const handleMenuOpen = () => setIsMenuOpen(true);

  const avatar = useMemo(() => get(params, 'profile.avatar.mediumImage'), [params]);

  return (
    <>
      <MenuItem className="header-user-item" ref={parentRef} onClick={handleMenuOpen}>
        <ListItemAvatar className="avatar-holder me-3">
          <Avatar alt="Remy Sharp" src={avatar || AvatarImg} className="avatar-image" />
          <div className="d-flex flex-column avatar-name">
            <span className="text-primary">{params.userName}</span>
            <span>{get(userNGOSelected, 'name')} <ArrowBackIosNewIcon className='top-arrow' /></span>
          </div>
        </ListItemAvatar>
      </MenuItem>
      <ProfileMenu
        isOpen={isMenuOpen}
        parentRef={parentRef}
        ngos={userNGOs}
        onClose={handleMenuClose}
        onSelectNGO={setUserNGO}
      />
    </>
  );
};

AvatarUser.defaultProps = {
  user: null
};

AvatarUser.propTypes = {
  user: PropTypes.object
};

export default AvatarUser;
