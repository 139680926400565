// Packages
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';

// Relatives
import BasicPopover from '../../../components/BasicPopover';
import MaskedInput from '../../../components/MaskedInput';

const FundraiserStep3 = () => {
  const { control } = useFormContext();

  return (
    <>
      <Typography className="inline-block text-muted">
        <span className="mb-4">How much are you trying to raise during your Fundraiser?</span>
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="target_goal"
          control={control}
          rules={{ required: 'Goal is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Goal
                </label>
                <BasicPopover message="You can always change your goal amount later. Not sure where to start? Most campaigns have a goal of $1,000." />
              </div>
              <TextField
                {...field}
                fullWidth
                variant="standard"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    name: field.name,
                    valueMasked: false,
                    mask: '$num',
                    blocks: {
                      num: {
                        scale: 2,
                        mask: Number,
                        thousandsSeparator: ',',
                        normalizeZeros: true,
                        // padFractionalZeros: true,
                        radix: '.'
                      }
                    }
                  }
                }}
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            </div>
          )}
        />
      </Grid>
      <div className="step-3__transactions">
        <Typography color="primary" variant="h4" className="">
          Your Organization KEEPS 100%
        </Typography>
        <Typography className="inline-block">
          <span className="mb-4">
            Keep in mind Fans earn PickCoin Rewards with EVERY donation and the transaction fees, including credit and
            debit charges, are added to their donation. No Fees. No Waiting. No Hassle! Within 24-72 hours, donations
            will be automatically sent to your registered bank account FREE TO YOU so you don&apos;t have to worry about
            withdrawing the money.
          </span>
        </Typography>
        <Typography color="primary" variant="h5" className="inline-block step-3__sub-title">
          No Fees. No Waiting. No Hassle!
        </Typography>
        <Typography className="inline-block">
          <span className="mb-4">
            Within 24-72 hours, donations will be automatically sent to your registered bank account FREE TO YOU so you
            don&apos;t have to worry about withdrawing the money.
          </span>
        </Typography>
      </div>
    </>
  );
};

FundraiserStep3.defaultProps = {};

FundraiserStep3.propTypes = {};

export default FundraiserStep3;
