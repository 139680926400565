// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Participant = props => {
  const { name, className, avatarImage } = props;
  const [isHovered, setIsHovered] = useState(false);
  
  const avatarStyle = {};
  if (avatarImage) {
    avatarStyle.backgroundImage = `url(${avatarImage})`;
  }

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={classNames('participant', { [className]: className, 'participant--hovered': isHovered })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={name}
      style={avatarStyle}
    />
  );
};

Participant.defaultProps = {
  className: '',
  name: '',
  avatarImage: null
};

Participant.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  avatarImage: PropTypes.string
};

export default Participant;
