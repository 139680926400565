// Packages
import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

const BasicPopover = props => {
  const { message } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="ms-1">
      <InfoIcon color="primary" variant="contained" onClick={handleClick} sx={{ cursor: 'pointer' }} />
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Typography sx={{ p: 2, maxWidth: 450 }}>{message}</Typography>
      </Popover>
    </div>
  );
};

BasicPopover.defaultProps = {
  message: ''
};

BasicPopover.propTypes = {
  message: PropTypes.string
};

export default BasicPopover;
