// Packages
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const InfiniteScroll = props => {
  const { children, containerNode, observedNode, threshold, onCallback, disabled } = props;
  const observer = useRef(null);
  const observedNodeRef = useRef(observedNode);

  const handleObserver = useCallback((entities /* , observer */) => {
    const { intersectionRatio } = entities[0];
    if (intersectionRatio > threshold && observedNodeRef.current) {
      observer.current.unobserve(observedNodeRef.current);
      if (!disabled) {
        onCallback();
      }
    }
  }, []);

  useEffect(() => {
    if (containerNode) {
      observer.current = new IntersectionObserver(handleObserver, {
        root: containerNode,
        rootMargin: '0px',
        threshold
      });
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [containerNode, handleObserver]);

  useEffect(() => {
    if (observedNode && observer.current) {
      observedNodeRef.current = observedNode;
      observer.current.observe(observedNode);
    }

    return () => {
      if (observedNode && observer.current) {
        observedNodeRef.current = null;
        observer.current.unobserve(observedNode);
      }
    };
  }, [observedNode, observer.current]);

  return <>{children}</>;
};

InfiniteScroll.defaultProps = {
  children: null,
  disabled: false,
  containerNode: null,
  observedNode: null,
  threshold: 1.0,
  onCallback: noop
};

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  containerNode: PropTypes.object,
  observedNode: PropTypes.object,
  threshold: PropTypes.number,
  onCallback: PropTypes.func
};

export default InfiniteScroll;
